import { styled } from '@linaria/react';
import { StyledContentBlock as ContentBlock } from './ContentBlock';
import { StyledWidgetIframe as WidgetIframe, widgetIframeFragment } from '../WidgetIframe.jsx';
import { screenReaderOnly } from '../ContentDetailHeader/ScreenReaderOnly.jsx';

export const widgetContentBlockFragment = widgetIframeFragment;

export const WidgetContentBlock = ({
  contents,
  pageSectionId,
  className,
}) => {
  const [widget] = contents;

  return (
    <ContentBlock className={className}
      headlineIsA="h2"
      pageSectionId={pageSectionId}
      headlineTranslation="content_block.widget.headline"
    >
      <WidgetIframe widget={widget} />
    </ContentBlock>
  );
};

export const StyledWidgetContentBlock = styled(WidgetContentBlock)`
  .content-block h2 {
      ${screenReaderOnly}
  }
`;
