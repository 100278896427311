import { StyledCarouselTeaserContainer as CarouselTeaserContainer } from '../../ContentTeaserSnippets/ContentTeaserPartials/CarouselTeaserContainer.jsx';
import {
  StyledSmallImageMixContentTeaser as SmallImageMixContentTeaser,
  darkSmallImageMixContentTeaserStyles,
  smallImageMixContentTeaserFragment,
} from '../../ContentTeaserSnippets/ContentTeasers/SmallImageMixContentTeaser.jsx';

export const moreOnTopStoryTeaserFragment = smallImageMixContentTeaserFragment;

export const MoreOnTopStoryContentTeaser = ({
  content, className, titleIsA, isHidden, ...restProps
}) => (
  <CarouselTeaserContainer
    className={className}
    isHidden={isHidden}
    {...restProps}
  >
    <SmallImageMixContentTeaser
      content={content}
      titleIsA={titleIsA}
      isHidden={isHidden}
      hideDescription
    />
  </CarouselTeaserContainer>
);

export const darkMoreOnTopStoryContentTeaserStyles = darkSmallImageMixContentTeaserStyles;

export const StyledMoreOnTopStoryContentTeaser = MoreOnTopStoryContentTeaser;
