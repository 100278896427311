import gql from 'graphql-tag';

import { StyledSlideShow as SlideShow, slideShowFragment } from '../../../components/slideshow';
import { gtmDataLayerFragment } from '../../../components/GoogleTagManager';
import { makeMemoFragment } from '../../graphql';

export const replacerImageGalleryFragment = makeMemoFragment({
  name: 'RichTextImageGalleryPlaceholders',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
        imageGalleries {
          id
          ...${slideShowFragment.name}
          ...${gtmDataLayerFragment.name}
        }
      }
      ${slideShowFragment.fragment()}
      ${gtmDataLayerFragment.fragment()}
    `;
  },
});

export const imageGalleryPlaceholderToSlideshowComponentFactory = content => matchedElem => {
  const imageGalleryId = matchedElem.dataset.id;
  const imageGallery = content.imageGalleries.find(ig => ig.id === +imageGalleryId);

  return imageGallery && (
    <SlideShow imageGallery={imageGallery}/>
  );
};
