import { NavigationWithFallback } from '../components/CoCo/Navigation';
import { WithGraphQLContentHandling } from '../components/commons/WithGraphQLContentHandling';
import { useParams } from '../dwouter';

export const NavigationPage = () => {
  const { contentId, langCode } = useParams();
  const queryDef = {
    path: `${langCode}/content/navigation/${contentId}`,
    depts: [langCode, contentId],
  };
  return (
    <WithGraphQLContentHandling queryDef={queryDef}>
      {({ content }) => (
        <NavigationWithFallback navigation={content} />
      )}
    </WithGraphQLContentHandling>
  );
};
