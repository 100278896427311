import gql from 'graphql-tag';
import { Redirect, useLocation, useParams } from '../dwouter';
import { StyledHeader as Header, headerFragmentFactory } from '../components/Header';
import { StyledInfoLayer as InfoLayer } from '../components/InfoLayer';
import { StyledCoCoPageHeadlineInHeader as CoCoPageHeadlineInHeader } from '../components/CoCo/CoCoPageHeadlineInHeader.jsx';
import { StyledFooter as Footer, footerFragmentFactory } from '../components/Footer';
import { StyledQuickNavigationList as QuickNavigationList } from '../components/a11y/QuickNavigationList.jsx';
import { BrowserOnlyBreakingBanner } from '../components/BreakingBanner';
import { StyledPremiumGptSlot as PremiumGptSlot } from '../components/GptSlot';
import { overviewPremiumSlotConfig } from '../components/GptSlot/slotConfigs';

import { PageWithData, pageWithDataFragment } from './PageWithData.jsx';
import { NotFoundPage } from './NotFoundPage.jsx';
import { GtmScriptWithDataLayer, gtmDataLayerFragment } from '../components/GoogleTagManager';
import { useFrontendConfig } from '../components/FrontendConfigContext.jsx';
import { PageHeaderMetadata, pageHeaderMetadataFragment } from '../components/PageHeaderMetadata.jsx';
import { StyledProgramsOverviewCoCo as ProgramsOverviewCoCo, programsOverviewFragment } from '../components/CoCo/ProgramsOverview';
import { isPathMatching } from '../components/commons/PathAnalyser';

export const programsOverviewQuery = () => {
  const headerFragment = headerFragmentFactory();
  const footerFragment = footerFragmentFactory();
  return gql`
  query programsOverviewData($lang: Language!, $id: Int) {
    programsOverview(lang: $lang) {
      id: originId
      ...${pageWithDataFragment.name}
      ...${headerFragment.name}
      ...${footerFragment.name}
      ...${pageHeaderMetadataFragment.name}
      ...${gtmDataLayerFragment.name}
      ...${programsOverviewFragment.name}
    }
  }
  ${pageWithDataFragment.fragment()}
  ${gtmDataLayerFragment.fragment()}
  ${headerFragment.fragment()}
  ${footerFragment.fragment()}
  ${pageHeaderMetadataFragment.fragment()}
  ${programsOverviewFragment.fragment()}
`;
};

export const ProgramsOverviewPageNoQuery = ({ content }) => {
  const { showProgramsOverview = false } = useFrontendConfig();

  if (!showProgramsOverview) {
    return (
      <NotFoundPage />
    );
  }

  return (
    <>
      <PageHeaderMetadata content={content}/>
      <GtmScriptWithDataLayer content={content}/>
      <InfoLayer content={content}/>
      <QuickNavigationList content={content}/>
      <Header content={content}>
        {({ isHeaderInWhiteState }) => (isHeaderInWhiteState
          ? (
            <CoCoPageHeadlineInHeader navigation={content} />
          )
          : null
        )}
      </Header>
      <BrowserOnlyBreakingBanner />
      <PremiumGptSlot slotConfig={overviewPremiumSlotConfig}/>
      <ProgramsOverviewCoCo content={content} />
      <Footer content={content}/>
    </>
  );
};

export const ProgramsOverviewPage = () => {
  const { langCode } = useParams();

  const queryDef = {
    path: `${langCode}/programs-overview`,
    depts: [langCode],
  };
  const [{ pathname, search }] = useLocation();

  return (
    <PageWithData queryDef={queryDef} langBaseContentFn={data => data.programsOverview}>
      {({ programsOverview }) => {
        if (!programsOverview) {
          return (
            <NotFoundPage />
          );
        }
        const { namedUrl } = programsOverview;

        if (!isPathMatching({ namedUrl, pathname })) {
          return <Redirect to={{ pathname: namedUrl, search }} />;
        }

        return (
          <ProgramsOverviewPageNoQuery content={programsOverview} />
        );
      }}
    </PageWithData>
  );
};
