import gql from 'graphql-tag';
import { makeMemoFragment } from '../../graphql';
import { StyledWidgetIframe as WidgetIframe, widgetIframeFragment } from '../../../components/WidgetIframe.jsx';
import { toLang } from '../../langMapper';

export const replacerWidgetFragment = makeMemoFragment({
  name: 'RichTextWidgetPlaceholders',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
        widgets {
          id
          ...${widgetIframeFragment.name}
        }
    }
    ${widgetIframeFragment.fragment()}
    `;
  },
});

export const widgetPlaceholderToWidgetComponent = matchedElem => {
  const { id, langCode, vendorCmpId } = matchedElem.dataset;
  const widget = { id: +id, language: toLang(langCode), vendorCmpId };

  return (
    <WidgetIframe widget={widget}/>
  );
};
