import gql from 'graphql-tag';
import { StyledAVCarousel as AVCarousel, avCarouselFragment } from './AVCarousel.jsx';
import { makeMemoFragment } from '../../../utils/graphql';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

export const avCarouselProfileTopicVideoAdapterFragment = avCarouselFragment;

export const avCarouselProfileTopicVideoAdapterConfigurationFragment = makeMemoFragment({
  name: 'AVPCarouselProfileTopicVideoAdapterConfiguration',
  fragment() {
    return gql`fragment ${this.name} on CompositionComponent {
        configuration {
          freeTitle
        }
      }
    `;
  },
});

export const AVCarouselProfileTopicVideoAdapter = ({ configuration, contents, ...otherProps }) => {
  const hasFeatureFlag = useFeatureFlag();
  if (!hasFeatureFlag) {
    return null;
  }

  return (
    <AVCarousel
      headlineTranslation={configuration.freeTitle}
      contents={contents}
      {...otherProps}
    />
  );
};
