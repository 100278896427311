import {
  StyledListDefaultUnifiedBigImageText as ListDefaultUnifiedBigImageTextTemplate,
  listDefaultUnifiedBigImageTextFragment,
} from '../../ContentTeaserSnippets/Templates/ListDefaultUnifiedBigImageText.jsx';

export const panoramaItemsFragment = listDefaultUnifiedBigImageTextFragment;

export const PanoramaItems = props => (
  <ListDefaultUnifiedBigImageTextTemplate {...props}/>
);

export const StyledPanoramaItems = PanoramaItems;
