import { isServer } from '../../utils/ssr';
import globals from '../../utils/globals';

import { GtmLoadScript } from './GtmLoadScript.jsx';
import { GtmDataLayer } from './GtmDataLayer.jsx';

export { gtmDataLayerFragment } from './GtmDataLayer.jsx';

export const GtmScriptWithDataLayer = ({ content }) => {
  const isGtmAlreadyInitialized = !isServer() && !!globals.window.dataLayer;
  return (
    <>
      <GtmDataLayer content={content} push={isGtmAlreadyInitialized}/>
      {!isGtmAlreadyInitialized && <GtmLoadScript /> }
    </>
  );
};
