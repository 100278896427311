import { styled } from '@linaria/react';
import { StyledTeaserLinkedImage as TeaserLinkedImage } from '../../ContentTeaserSnippets/ContentTeaserPartials/TeaserLinkedImage.jsx';
import { aspectRatioAware } from '../../../utils/css';
import { ultraWideScreenCenterHeroImages, ultraWideScreenHeroStyles } from '../../../utils/ultraWideCustomCss';
import { usePageContext } from '../../PageEmbeddingContext.jsx';

export const TopStoryHeroImage = ({ className }) => {
  const pageContext = usePageContext();
  return (
    <TeaserLinkedImage className={className}
      preload={!pageContext.isOnDetailPage}
    />
  );
};

export const StyledTopStoryHeroImage = styled(TopStoryHeroImage)`
  ${aspectRatioAware.xl.ultraWide`
    ${ultraWideScreenHeroStyles}
    ${ultraWideScreenCenterHeroImages}
  `}
`;
