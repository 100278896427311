import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { StyledBasicLayout as BasicLayout } from '../layouts/BasicLayout';
import { articleTopSlotConfig, articleContentBottomSlotConfig } from '../GptSlot/slotConfigs';
import {
  articleDetailHeaderFragment,
  darkArticleDetailHeaderStyles,
  StyledArticleDetailHeader as ArticleDetailHeader,
} from '../ContentDetailHeader/ArticleDetailHeader.jsx';
import { mediaDetailsHeaderFragment, StyledMediaDetailsHeader as MediaDetailsHeader } from '../ContentDetailHeader/MediaDetailsHeader.jsx';
import { StyledLiveblogRichText as LiveblogRichText, darkLiveblogRichTextStyles, liveblogRichTextFragment } from './LiveblogRichText.jsx';
import {
  contentDetailFooterFragment,
  darkContentDetailFooterStyles,
  StyledContentDetailFooter as ContentDetailFooter,
} from '../ContentDetailFooter';
import { partialFragments } from '../ContentTeaserSnippets/ContentTeaserPartials';
import {
  darkObservedContentDetailActionStyles,
  ObservedContentDetailActions,
  observedContentDetailActionsFragment,
} from '../ObservedContentDetailActions.jsx';
import {
  StyledBeforeRichTextGptSlot as BeforeRichTextGptSlot,
  StyledAfterRichTextGptSlot as AfterRichTextGptSlot,
  darkGptSlotStyles,
} from '../GptSlot';
import { makeMemoFragment } from '../../utils/graphql';
import {
  expandableLiveblogPostListFragment,
  darkExpandableLiveblogPostListStyles,
  StyledExpandableLiveblogPostList as ExpandableLiveblogPostList,
} from './ExpandableLiveblogPostList.jsx';
import { livestreamContentHeroFragment, LivestreamContentHero } from '../LivestreamContentHero.jsx';
import { StyledDetailPageHeroImage as DetailPageHeroImage, detailPageHeroImageFragment } from '../DetailPageHeroImage.jsx';
import { darkLiveblogPostStyles } from '../LiveblogPost';

export const liveblogDetailsFragment = makeMemoFragment({
  name: 'LiveblogDetails',
  fragment() {
    return gql`fragment ${this.name} on Liveblog {
            hasMinTextLength
            isLiveVideo
            ...${liveblogRichTextFragment.name}
            ...${contentDetailFooterFragment.name}
            ...${observedContentDetailActionsFragment.name}
            ...${partialFragments.opinionFragment.name}
            ...${expandableLiveblogPostListFragment.name}
            ...${livestreamContentHeroFragment.name}
            ...${mediaDetailsHeaderFragment.name}
            ...${detailPageHeroImageFragment.name}
            ...${articleDetailHeaderFragment.name}
        }
        ${liveblogRichTextFragment.fragment()}
        ${contentDetailFooterFragment.fragment()}
        ${observedContentDetailActionsFragment.fragment()}
        ${expandableLiveblogPostListFragment.fragment()}
        ${partialFragments.opinionFragment.fragment()}
        ${livestreamContentHeroFragment.fragment()}
        ${mediaDetailsHeaderFragment.fragment()}
        ${detailPageHeroImageFragment.fragment()}
        ${articleDetailHeaderFragment.fragment()}
    `;
  },
});

const LiveblogDetailsPartials = {
  Default: {
    DetailHeader: ArticleDetailHeader,
    hasHeroImg: true,
  },
  LiveVideo: {
    HeroCmpOverwrite: LivestreamContentHero,
    DetailHeader: MediaDetailsHeader,
  },
};

export const LiveblogDetails = ({ content = {}, className }) => {
  const { hasMinTextLength, isLiveVideo } = content;
  const { DetailHeader, HeroCmpOverwrite, hasHeroImg } = isLiveVideo
    ? LiveblogDetailsPartials.LiveVideo
    : LiveblogDetailsPartials.Default;

  return (
    <BasicLayout
      className={className}
      HeroCmp={HeroCmpOverwrite}
      heroCmpProps={HeroCmpOverwrite && {
        content,
      }}
    >
      <DetailHeader content={content}/>
      <ObservedContentDetailActions content={content} />
      {hasHeroImg && <DetailPageHeroImage content={content} />}
      <BeforeRichTextGptSlot slotConfig={articleTopSlotConfig}/>
      <LiveblogRichText content={content} />
      <ExpandableLiveblogPostList content={content} />
      <ContentDetailFooter className="footer" content={content}/>
      {hasMinTextLength &&
        <AfterRichTextGptSlot slotConfig={articleContentBottomSlotConfig}/>
      }
    </BasicLayout>
  );
};

export const darkLiveblogDetailsStyles = cx(
  darkArticleDetailHeaderStyles,
  darkObservedContentDetailActionStyles,
  darkGptSlotStyles,
  darkLiveblogRichTextStyles,
  darkLiveblogPostStyles,
  darkExpandableLiveblogPostListStyles,
  darkContentDetailFooterStyles,
);

export const StyledLiveblogDetails = styled(LiveblogDetails)`
  .footer {
    margin-top: 30px;
  }
`;
