import { useEffect } from 'react';
import { useLocation } from '../../dwouter';
import { useMobileAppContext } from '../../components/hooks/mobileAppContext';

export const richTextInternalLinkSelector = 'a.internal-link';

export const useRouterLinksEffect = ({ content, richTextRef }) => {
  const [, setLocation] = useLocation();
  const { isMobileMode } = useMobileAppContext();
  useEffect(() => {
    richTextRef.current?.querySelectorAll(richTextInternalLinkSelector)
      .forEach(linkElem => {
        const href = linkElem.getAttribute('href');
        linkElem.addEventListener('click', e => {
          e.preventDefault();
          // eslint-disable-next-line fp/no-mutating-methods
          setLocation(href, { isMobileMode, scrollTop: true });
        });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.id, setLocation]);
};
