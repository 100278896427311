import {
  StyledListColumnUnifiedSmallImageMix as ListColumnUnifiedSmallImageMix,
  listColumnUnifiedSmallImageMixFragment, darkListColumnUnifiedSmallImageMixStyles,
} from '../../ContentTeaserSnippets/Templates/ListColumnUnifiedSmallImageMix.jsx';

export const atpItemsListFragment = listColumnUnifiedSmallImageMixFragment;

export const AtpItemsList = ({ className, contents }) => (
  <ListColumnUnifiedSmallImageMix
    className={className}
    contents={contents}
    headlineTranslation="content_block.atp_all_items_list.headline"
    pageSectionId="atp-all-items-list"
  />
);

export const darkAtpItemsListStyles = darkListColumnUnifiedSmallImageMixStyles;

export const StyledAtpItemsList = AtpItemsList;
