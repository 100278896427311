import { styled } from '@linaria/react';

import { colors, resolutions } from '../../../utils/css';
import { useSlotSetupEffect } from '../useSlotSetupEffect';
import { useSlotLazyLoadEffect } from '../useSlotLazyLoadEffect';
import { getElementId } from '../slotCommons';
import { BrowserOnly } from '../../BrowserOnly.jsx';

export const SingleResolutionGptSlot = ({
  slotConfigEntry,
  className,
  style,
}) => {
  const slot = useSlotSetupEffect({ slotConfigEntry });
  useSlotLazyLoadEffect({ slot, slotConfigEntry });

  return (
    <div
      id={getElementId(slotConfigEntry)}
      className={className}
      style={{
        ...style,
        backgroundColor: slot ? 'transparent' : colors.WARM_GREY_02,
      }}
    />
  );
};

export const SsrCompatibleSingleResolutionGptSlot = ({
  slotConfigEntry,
  className,
  style,
}) => {
  const fallback = (
    <div
      id={getElementId(slotConfigEntry)}
      className={className}
      style={{
        ...style,
        backgroundColor: colors.WARM_GREY_02,
      }}
    />
  );
  return (
    <BrowserOnly fallback={fallback}>
      <SingleResolutionGptSlot style={style} className={className} slotConfigEntry={slotConfigEntry}/>
    </BrowserOnly>
  );
};

const getDisplayValueFor = (resolution = 'xs') => props => {
  const minResolutionWidth = resolutions.min[resolution];
  const maxResolutionWidth = resolutions.max[resolution];
  const { slotConfigEntry } = props;
  if (maxResolutionWidth < slotConfigEntry.minWidth) {
    return 'none';
  }
  if (minResolutionWidth > slotConfigEntry.maxWidth) {
    return 'none';
  }
  return 'block';
};

export const StyledSingleResolutionGptSlot = styled(SsrCompatibleSingleResolutionGptSlot)`
    width: ${props => {
    const { slotConfigEntry } = props;
    if (Array.isArray(slotConfigEntry.slotSize)) {
      return 'initial';
    }
    return `${slotConfigEntry.slotSize.width}px`;
  }};
    height: ${props => {
    const { slotConfigEntry } = props;
    if (Array.isArray(slotConfigEntry.slotSize)) {
      return 'initial';
    }
    return `${slotConfigEntry.slotSize.height}px`;
  }};
  display: ${getDisplayValueFor('xs')};
  @media (min-width:${resolutions.min.sm}px) {
    display: ${getDisplayValueFor('sm')};
  }
  @media (min-width:${resolutions.min.md}px) {
    display: ${getDisplayValueFor('md')};
  }
  @media (min-width:${resolutions.min.lg}px) {
    display: ${getDisplayValueFor('lg')};
  }
  @media (min-width:${resolutions.min.xl}px) {
    display: ${getDisplayValueFor('xl')};
  }  
  @media (min-width:${resolutions.min.wide_xl}px) {
    display: ${getDisplayValueFor('wide_xl')};
  }
  
   
`;
