import { StyledBeforeRichTextGptSlot as GptSlot } from '../../../components/GptSlot';
import { multiSizeSlot } from '../../../components/GptSlot/slotConfigs';

export const mapAdPlaceholderToGptSlot = matchedElem => {
  const { slotId } = matchedElem.dataset;
  const slotConfig = multiSizeSlot(slotId, { isArticleInContentSlot: true });

  return (
    <GptSlot slotConfig={slotConfig}/>
  );
};
