import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from '../../dwouter';
import { GtmNoScriptFallback } from './GtmNoScriptFallback.jsx';
import { useStaticInfoForGtm } from './hooks/useStaticInfoForGtm';
import { gtmDataLayerPartial, TrackingUtils } from './utils/TrackingUtils';

export const gtmDataLayerFragment = gtmDataLayerPartial;

export const GtmDataLayer = ({ content, push = false }) => {
  const {
    level1Id, macaParam, pageOrigin, gtmEnvironment,
  } = useStaticInfoForGtm();
  const { pageNumber } = useParams();

  const dataLayer = TrackingUtils.generateDataLayer({
    content,
    macaParam,
    level1Id,
    pageOrigin,
    environment: gtmEnvironment,
    pageNumber: +pageNumber || undefined,
  });

  useEffect(() => {
    if (push) {
      TrackingUtils.pushToGoogleTagManager({
        withReset: true,
        datalayerObj: {
          event: 'onPageChange',
          ...dataLayer,
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <script>
          {TrackingUtils.initDataLayerScript(dataLayer)}
        </script>
      </Helmet>
      <GtmNoScriptFallback dataLayer={dataLayer}/>
    </>
  );
};
