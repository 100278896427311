import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';

import { mediaMin, colors } from '../../../utils/css';
import { commonBlockSpacing } from '../ContentBlock';
import { StyledTeaserText as TeaserText, darkTeaserTextStyles } from '../../TeaserText.jsx';
import { StyledExplainerTeaser as ExplainerTeaser, darkExplainerTeaserStyles, explainerTeaserFragment } from './ExplainerTeaser.jsx';
import { StyledTopicImageWithCustomTitle as TopicImageWithCustomTitle, darkTopicImageWithCustomTitleStyles, topicImageWithCustomTitleFragment } from './TopicImageWithCustomTitle.jsx';
import { StyledTopicHeaderTitle as TopicHeaderTitle, darkTopicHeaderTitleStyles } from './TopicHeaderTitle.jsx';
import { makeMemoFragment } from '../../../utils/graphql';
import { withTheme } from '../../higherOrderComponents/withTheme.jsx';

export const topicHeaderFragment = makeMemoFragment({
  name: 'TopicHeader',
  fragment() {
    return gql`fragment ${this.name} on AutoTopic {
          teaser
          name
          explainer {
            ...${explainerTeaserFragment.name}
          }
          ...${topicImageWithCustomTitleFragment.name}
        }
        ${topicImageWithCustomTitleFragment.fragment()}
        ${explainerTeaserFragment.fragment()}
    `;
  },
});

export const cssVariables = {
  bgColor: '--topic-header-bg-color',
};

const darkTopicHeaderStyles = cx(
  darkTeaserTextStyles,
  darkExplainerTeaserStyles,
  darkTopicHeaderTitleStyles,
  css`${cssVariables.bgColor}: ${colors.DARK_BLUE_GREY_02};`,
);

export const TopicHeader = ({
  content,
  className,
}) => (
  <header className={className}>
    <TopicImageWithCustomTitle
      className={cx('image-with-title', darkTopicImageWithCustomTitleStyles)}
      content={content}
      preload
      titleSlotFn={({ hasImage }) => (
        <TopicHeaderTitle
          className='custom-topic-title'
          aboveImage={hasImage}
        >
          {content.name}
        </TopicHeaderTitle>
      )}
    />

    <div className="full-background">
      <div className={cx('container-wrapper', commonBlockSpacing)}>
        <TeaserText className="teaser-text">{content.teaser}</TeaserText>
        <ExplainerTeaser className="explainer-teaser" content={content.explainer}/>
      </div>
    </div>
  </header>
);

export const StyledTopicHeader = styled(TopicHeader)`
  .image-with-title .topic-title:first-child .custom-topic-title {
    margin-top: 20px;
  }

  .full-background {
    background-color:  var(${cssVariables.bgColor}, ${colors.BLUE_GREY_01});
  }

  .container-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .teaser-text {
    text-align: center;
  }

  .explainer-teaser {
    padding-top: 30px;
  }

  ${mediaMin.md`
      .image-with-title .topic-title:first-child .custom-topic-title{
        margin-top: 30px;
      }
      .container-wrapper {
        padding-top: 30px;
      }
      .teaser-text {
        font-size: 1.6rem;
        text-align: start;
        width: 50%;
        padding-inline-end: 15px;
      }
      .teaser-text:last-child {
        width: 100%;
        text-align: center;
        padding-inline-end: 0;
      }
      .explainer-teaser {
        width: 50%;
        padding-top: 0;
        padding-inline-start: 15px;
      }
    `}

  ${mediaMin.lg`
      .image-with-title .topic-title:first-child .custom-topic-title{
        margin-top: 40px;
      }
      .teaser-text {
        font-size: 1.8rem;
      }
      .teaser-text:last-child {
        padding-inline: 80px;
      }
    `}

  ${mediaMin.xl`
      .teaser-text:last-child {
        padding-inline: 95px;
      }
    `}
`;

StyledTopicHeader.darkStyles = darkTopicHeaderStyles;

export const StyledTopicHeaderWithTheme = withTheme(StyledTopicHeader)(darkTopicHeaderStyles);
