import { styled } from '@linaria/react';

import { cx } from '@linaria/core';
import { contentBlockWidth } from '../../ContentBlocks/ContentBlock';
import { mediaMin } from '../../../utils/css';

export const CarouselTeaserContainer = ({
  className,
  isHidden = false,
  children,
  ...restProps
}) => (
  <div
    className={cx(className, 'teaser-wrap')}
    aria-hidden={isHidden}
    {...restProps}
  >
    {children}
  </div>
);

export const StyledCarouselTeaserContainer = styled(CarouselTeaserContainer)`
  &.teaser-wrap {
    width: 100vw;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
  }

  ${mediaMin.sm`
    &.teaser-wrap {
      padding: 0 65px;
    }
  `};

  ${mediaMin.md`
     &.teaser-wrap {
      width: 50vw;
      padding: 0 15px;

      &:nth-child(odd) {
        padding-inline-start: 60px;
      }

      &:nth-child(even) {
        padding-inline-end: 60px;
      }
    }
  `};

  ${mediaMin.lg`
    &.teaser-wrap {
      width: ${contentBlockWidth.lg / 2}px; 
    }
  `};
 
  
  ${mediaMin.xl`
    &.teaser-wrap {
      &:nth-child(odd) {
        padding-inline-start: 65px;
      }

      &:nth-child(even) {
        padding-inline-end: 65px;
      }

      width: ${contentBlockWidth.xl / 2}px;
    }
  `};
`;
