import { useEffect, useState } from 'react';

import { loadVideoJsApi } from './api';
import { exposeVideoJsVersionToWindow } from '../../components/GoogleTagManager/utils/TrackingUtils';

export const useLoadPlayerLibEffect = ({ content, allowLoadPlayer }) => {
  const [playerLibState, setVideoJsLibState] = useState({ playerLib: null });

  useEffect(() => {
    let didCancel = false; // eslint-disable-line fp/no-let

    async function loadPlayerLib() {
      const { playerLib } = await loadVideoJsApi(content);

      if (!didCancel) {
        exposeVideoJsVersionToWindow(playerLib?.VERSION);
        setVideoJsLibState({
          playerLib,
        });
      }
    }

    if (allowLoadPlayer && !playerLibState?.playerLib) {
      loadPlayerLib();
    }
    return () => {
      didCancel = true; // eslint-disable-line fp/no-mutation
    };
  }, [content, allowLoadPlayer, playerLibState]);

  return playerLibState;
};
