import {
  StyledListDefaultUnifiedBigImageText as ListDefaultUnifiedBigImageTextTemplate,
  listDefaultUnifiedBigImageTextFragment,
} from '../../ContentTeaserSnippets/Templates/ListDefaultUnifiedBigImageText.jsx';

export const dwRecommendsTopicsFragment = listDefaultUnifiedBigImageTextFragment;

export const DwRecommendsTopics = props => (
  <ListDefaultUnifiedBigImageTextTemplate {...props}/>
);

export const StyledDwRecommendsTopics = DwRecommendsTopics;
