import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { colors } from '../../../utils/css';
import { makeMemoFragment } from '../../../utils/graphql';
import { StyledPageHeadline as PageHeadline } from '../../PageHeadline.jsx';
import { StyledA11yLinkTargetMarker as A11yLinkTargetMarker } from '../../a11y/A11yLinkTargetMarker.jsx';
import { StyledProgramList as ProgramList, programListFragment } from '../../ContentBlocks/ProgramList';
import { ids } from '../../../utils/staticElementIds';

export const programsOverviewFragment = makeMemoFragment({
  name: 'ProgramsOverviewCoCo',
  fragment() {
    return gql`fragment ${this.name} on ProgramsOverview {
      videoPrograms {
        ...${programListFragment.name}
      }
      audioPrograms {
        ...${programListFragment.name}
      }
      pageHeadline
    }
    ${programListFragment.fragment()}`;
  },
});

export const ProgramsOverviewCoCo = ({
  content,
  className,
}) => {
  const {
    pageHeadline,
    videoPrograms,
    audioPrograms,
  } = content;

  return (
    <A11yLinkTargetMarker className={className} id={ids.quickNav.mainContent}>
      <PageHeadline>{pageHeadline}</PageHeadline>
      <ProgramList pageSectionId='program-video-list' headlineTranslation={'content_block.program_list_videos.headline'} contents={videoPrograms} />
      <ProgramList pageSectionId='program-audio-list' headlineTranslation={'content_block.program_list_audios.headline'} contents={audioPrograms} />
    </A11yLinkTargetMarker>
  );
};

export const StyledProgramsOverviewCoCo = styled(ProgramsOverviewCoCo)`
  section {
    background-color: ${colors.DARK_BLUE_GREY_01};
  }
  section:nth-of-type(2n) {
    background-color: ${colors.DARK_BLUE_GREY_02};
  }
`;
