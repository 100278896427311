import gql from 'graphql-tag';
import { cx } from '@linaria/core';

import { StyledMoreOnTopic as MoreOnTopic, moreOnTopicFragment, darkMoreOnTopicStyles } from '../ContentBlocks/MoreOnTopic';
import { StyledMoreFromColumn as MoreFromColumn, moreFromColumnFragment, darkMoreFromColumnStyles } from '../ContentBlocks/MoreFromColumn';
import { StyledColumnInfo as ColumnInfo, columnInfoFragment, darkColumnInfoStyles } from '../ContentBlocks/ColumnInfo';
import { StyledProgramInfo as ProgramInfo, programInfoFragment, darkProgramInfoStyles } from '../ContentBlocks/ProgramInfo';
import {
  StyledDossierContentItems as DossierContentItems,
  dossierContentItemsFragment,
  darkDossierContentItemsStyles,
} from '../ContentBlocks/DossierContentItems';
import { StyledRelatedTopics as RelatedTopics, relatedTopicsFragment, darkRelatedTopicsStyles } from '../ContentBlocks/RelatedTopics';
import { isColumn, isDossier } from '../../utils/metaDataHelpers';
import {
  StyledMoreFromProgram as MoreFromProgram,
  moreFromProgramFragment,
} from '../ContentBlocks/MoreFromProgram';
import { makeMemoFragment } from '../../utils/graphql';
import { isLiveblog } from '../../utils/contentUtils';
import {
  darkRelatedVideosKeywordRegionStyles,
  relatedVideosKeywordRegion, StyledRelatedVideosKeywordRegion as RelatedVideosKeywordRegion,
} from '../ContentBlocks/RelatedVideosKeywordRegion';
import {
  relatedVideosThematicFocusRegion, StyledRelatedVideosThematicFocusRegion as RelatedVideosThematicFocusRegion,
} from '../ContentBlocks/RelatedVideosThematicFocusRegion';
import {
  relatedVideosThematicFocusGlobal, StyledRelatedVideosThematicFocusGlobal as RelatedVideosThematicFocusGlobal,
} from '../ContentBlocks/RelatedVideosThematicFocusGlobal';
import { extendWithClassNames } from '../../utils/extendWithClassNames.jsx';
import { hideOnPrint } from '../GlobalPrintStyles';

export const recommendedZoneFragment = makeMemoFragment({
  name: 'RecommendedZone',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on MetadataAspect {
        genre
      }
      ...${moreOnTopicFragment.name}
      ...${moreFromColumnFragment.name}
      ...${relatedTopicsFragment.name}
      ...${programInfoFragment.name}
      ...${moreFromProgramFragment.name}
      ...${dossierContentItemsFragment.name}
      ...${columnInfoFragment.name}
      ...${relatedVideosKeywordRegion.name}
      ...${relatedVideosThematicFocusRegion.name}
      ...${relatedVideosThematicFocusGlobal.name}
    }
    ${moreOnTopicFragment.fragment()}
    ${moreFromColumnFragment.fragment()}
    ${relatedTopicsFragment.fragment()}
    ${programInfoFragment.fragment()}
    ${moreFromProgramFragment.fragment()}
    ${dossierContentItemsFragment.fragment()}
    ${columnInfoFragment.fragment()}
    ${relatedVideosKeywordRegion.fragment()}
    ${relatedVideosThematicFocusRegion.fragment()}
    ${relatedVideosThematicFocusGlobal.fragment()}
  `;
  },
});

export const RecommendedZone = ({ className, content }) => (
  <>
    {isDossier(content) &&
      <DossierContentItems className={className} content={content}/>
    }
    {!isDossier(content) && !isLiveblog(content) &&
      <MoreOnTopic className={className} content={content}/>
    }
    {isColumn(content) &&
      <>
        <MoreFromColumn className={className} content={content}/>
        <ColumnInfo className={className} content={content}/>
      </>
    }
    <RelatedVideosKeywordRegion content={content} className={className}/>
    <RelatedVideosThematicFocusRegion content={content} className={className}/>
    <RelatedVideosThematicFocusGlobal content={content} className={className}/>
    <MoreFromProgram className={className} content={content}/>
    <ProgramInfo className={className} content={content}/>
    <RelatedTopics className={className} content={content}/>
  </>
);

export const darkRecommendedZoneStyles = cx(
  darkDossierContentItemsStyles,
  darkMoreOnTopicStyles,
  darkMoreFromColumnStyles,
  darkColumnInfoStyles,
  darkRelatedVideosKeywordRegionStyles,
  darkProgramInfoStyles,
  darkRelatedTopicsStyles,
);

export const StyledRecommendedZone = extendWithClassNames(RecommendedZone)(hideOnPrint);
