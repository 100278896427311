import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { StyledButtonBar as ButtonBar } from './buttons/Button/ButtonBar.jsx';
import { ButtonSecondary } from './buttons/Button';
import { LinkBase } from './Links/LinkBase';
import { StyledLeftChevron as LeftChevron, StyledRightChevron as RightChevron } from './icons/index';
import { InformationItalic } from './Text/Information.jsx';
import { useTranslations } from './hooks/useTranslation.jsx';
import { mediaMax } from '../utils/css';
import { commonBlockSpacing } from './ContentBlocks/ContentBlock';
import { useParams } from '../dwouter';

const defaultTranslationKey = 'component.pagination';

export const getLastPageNumber = (totalHits, pageSize) => Math.ceil(totalHits / pageSize);

export const Pagination = ({
  className, totalHits, pageSize, namedUrl,
}) => {
  const { pageNumber } = useParams();
  const currentPageNumber = +pageNumber;
  const lastPageNumber = getLastPageNumber(totalHits, pageSize);
  const translationKeys = [
    {
      key: `${defaultTranslationKey}.paging.label`,
      parameters: { currentPage: currentPageNumber, totalPages: lastPageNumber },
    },
    `${defaultTranslationKey}.label_aria`,
    `${defaultTranslationKey}.previous_button.label`,
    `${defaultTranslationKey}.next_button.label`,
    `${defaultTranslationKey}.previous_button.label_aria`,
    `${defaultTranslationKey}.next_button.label_aria`,
  ];
  const [
    pagingLabel,
    navAriaLabel,
    prevBtnText,
    nextBtnText,
    prevBtnAria,
    nextBtnAria,
  ] = useTranslations(translationKeys);
  const disablePrevButton = currentPageNumber === 1;
  const disableNextButton = currentPageNumber === lastPageNumber;
  const generatePath = pageNum => `${namedUrl}/page-${pageNum}`;

  return (
    <nav aria-label={navAriaLabel} className={cx(className, commonBlockSpacing)}>
      <ButtonBar align="space-between">
        <ButtonSecondary
          isA={disablePrevButton ? undefined : LinkBase}
          role="link"
          rel="prev"
          aria-label={prevBtnAria}
          to={!disablePrevButton && generatePath(currentPageNumber - 1)}
          className="prev"
          disabled={disablePrevButton}
        >
          <LeftChevron className="chevron-icon"/>
          {prevBtnText}
        </ButtonSecondary>
        <InformationItalic>{pagingLabel}</InformationItalic>
        <ButtonSecondary
          isA={disableNextButton ? undefined : LinkBase}
          role="link"
          rel="next"
          aria-label={nextBtnAria}
          to={!disableNextButton && generatePath(currentPageNumber + 1)}
          className="next"
          disabled={disableNextButton}
        >
          {nextBtnText}
          <RightChevron className="chevron-icon" />
        </ButtonSecondary>
      </ButtonBar>
    </nav>
  );
};

export const StyledPagination = styled(Pagination)`
  padding-block-end: 30px;

  span {
    align-self: center;
    font-size: 1.4rem;
  }

  .chevron-icon {
    width: 18px;
  }

  ${mediaMax.xs`
    span {
      text-align: center;
    }

    .prev {
      order: 1;
    }

    .next {
      order: 2;
    }
  `}
`;
