import gql from 'graphql-tag';

import { useQueryTranslationAndFrontendConfig } from '../components/hooks/useQueryTranslationAndFrontendConfig';
import { WithGraphQLHandling } from '../components/commons/WithGraphQLHandling.jsx';
import { LanguageContextProvider } from '../components/commons/WithGraphQLContentHandling/LanguageContext';
import { BootstrapLtrOverrideGlobalStyles } from '../components/commons/WithGraphQLContentHandling/BootstrapLtrOverrideGlobalStyles';
import { makeMemoFragment } from '../utils/graphql';
import { FrontendConfigContext } from '../components/FrontendConfigContext.jsx';
import { ConsentManagement } from '../components/ConsentManagement';
import { I18nContext } from '../components/I18nContext.jsx';
import { useGqlFetch } from '../components/hooks/useGqlFetch';

export const pageWithDataFragment = makeMemoFragment({
  name: 'PageWithData',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on ModelAspect {
        isRtl
        isEuropeanLang
        isAsianOrAfricanLang
        isChineseLang
        language
      }
    }
    `;
  },
});

export const contentLangBaseFn = data => data.content;

export const noRealContentLangBaseFn = data => data.topStoriesNavigation;

export const PageWithData = ({ queryDef, children, langBaseContentFn }) => {
  const { gqlMeta: translationGqlMeta, frontendConfig, i18n } = useQueryTranslationAndFrontendConfig();

  const gqlMeta = useGqlFetch(queryDef);
  const combinedGqlMeta = {
    ...gqlMeta,
    loading: gqlMeta.loading || translationGqlMeta.loading,
    error: gqlMeta.error || translationGqlMeta.error,
  };
  return (
    <WithGraphQLHandling gqlMeta={combinedGqlMeta} >
      {data => (
        <FrontendConfigContext.Provider value={frontendConfig}>
          <I18nContext.Provider value={i18n}>
            <LanguageContextProvider content={langBaseContentFn(data)} >
              <ConsentManagement/>
              <BootstrapLtrOverrideGlobalStyles />
              {children(data)}
            </LanguageContextProvider>
          </I18nContext.Provider>
        </FrontendConfigContext.Provider>
      )}
    </WithGraphQLHandling>
  );
};
