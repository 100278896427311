import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';
import { StyledButtonBase, cssVariables as buttonBaseCssVariables } from './ButtonBase.jsx';
import { colors, selector } from '../../../utils/css';
import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';

export const cssVariables = {
  secondaryBg: '--button-secondary-background-color',
  tertiaryBoxShadowColor: '--button-tertiary-box-shadow-color',
};

// @VisibleForTesting
export const stylesForText = css`
  width: fit-content; 
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  word-break: break-word; 
  
  overflow: hidden;
`;

// @VisibleForTesting
export const mediumSizeStyles = css`
  padding: 0 30px;
  min-height: 48px;
`;

// @VisibleForTesting
export const smallSizeStyles = css`
padding: 5px 6px;
`;

// @VisibleForTesting
export const extendedDisabledStyles = css`
  &${selector.disabled} {
    background: var(${buttonBaseCssVariables.disabledBg}, ${colors.BLUE_GREY_02});
    color: ${colors.BLUE_GREY_03};
    box-shadow: none;
  }
`;

// Icon
export const ButtonWithIconOnly = styled(StyledButtonBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
`;
ButtonWithIconOnly.darkStyles = StyledButtonBase.darkStyles;

// Primary
// @VisibleForTesting
export const primaryButtonStyles = css`
  && {
    background: ${colors.DW_YELLOW};
  }
  ${buttonBaseCssVariables.color}: ${colors.DW_DARK_BLUE};
`;

export const ButtonPrimary = extendWithClassNames(StyledButtonBase)(
  stylesForText,
  mediumSizeStyles,
  extendedDisabledStyles,
  primaryButtonStyles,
);
ButtonPrimary.darkStyles = cx(
  StyledButtonBase.darkStyles,
  css`
  .${primaryButtonStyles}{
    ${buttonBaseCssVariables.color}: ${colors.DW_DARK_BLUE};
  }`,
);

export const ButtonPrimarySmall = extendWithClassNames(StyledButtonBase)(
  stylesForText,
  smallSizeStyles,
  extendedDisabledStyles,
  primaryButtonStyles,
);
ButtonPrimarySmall.darkStyles = ButtonPrimary.darkStyles;

// Secondary
export const ButtonSecondary = extendWithClassNames(StyledButtonBase)(
  stylesForText,
  mediumSizeStyles,
  extendedDisabledStyles,
  css`
  && {
    background: var(${cssVariables.secondaryBg}, ${colors.BLUE_GREY_02});
  }
`,
);
ButtonSecondary.darkStyles = cx(
  StyledButtonBase.darkStyles,
  css`${cssVariables.secondaryBg}: ${colors.BLUE_GREY_04};`,
);
// Tertiary
// @VisibleForTesting
export const tertiaryStyles = css`
box-shadow: inset 0 0 0 2px var(${cssVariables.tertiaryBoxShadowColor}, ${colors.BLUE_GREY_03});
`;

export const ButtonTertiary = extendWithClassNames(StyledButtonBase)(
  stylesForText,
  mediumSizeStyles,
  extendedDisabledStyles,
  tertiaryStyles,
);
ButtonTertiary.darkStyles = cx(
  StyledButtonBase.darkStyles,
  css`${cssVariables.tertiaryBoxShadowColor}: ${colors.BLUE_GREY_04};`,
);

export const ButtonTertiaryWithIconOnly = extendWithClassNames(ButtonWithIconOnly)(tertiaryStyles);
ButtonTertiaryWithIconOnly.darkStyles = ButtonTertiary.darkStyles;

export const ButtonTertiarySmall = extendWithClassNames(StyledButtonBase)(
  stylesForText,
  smallSizeStyles,
  extendedDisabledStyles,
  tertiaryStyles,
);
ButtonTertiarySmall.darkStyles = ButtonTertiary.darkStyles;

// Semi-Transparent
// @VisibleForTesting
export const semiTransparentStyles = cx(
  StyledButtonBase.darkStyles,
  css`background-color: rgba(0, 8, 33, 0.45);`,
);

export const ButtonSemiTransparent = extendWithClassNames(StyledButtonBase)(
  stylesForText,
  mediumSizeStyles,
  extendedDisabledStyles,
  semiTransparentStyles,
);
ButtonSemiTransparent.darkStyles = '';

export const ButtonSemiTransparentWithIconOnly = extendWithClassNames(ButtonWithIconOnly)(semiTransparentStyles);
ButtonSemiTransparentWithIconOnly.darkStyles = ButtonSemiTransparent.darkStyles;

// Blue icon button (video player button)
export const ButtonBlueWithIconOnly = styled(ButtonWithIconOnly)`
  ${buttonBaseCssVariables.color}: ${colors.WHITE};
  ${buttonBaseCssVariables.background}: ${colors.DW_LIGHT_BLUE};
`;
ButtonBlueWithIconOnly.darkStyles = ButtonWithIconOnly.darkStyles;
