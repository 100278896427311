import gql from 'graphql-tag';
import { cx } from '@linaria/core';

import {
  Partials, StyledContentTeaser as ContentTeaser, partialFragments, darkPartialStyles,
} from '../ContentTeaserPartials';
import { gtmDataLayerPartial } from '../../GoogleTagManager/utils/TrackingUtils';
import { makeMemoFragment } from '../../../utils/graphql';

export const bigAvTextContentTeaserFragment = makeMemoFragment({
  name: 'BigAvTextContentTeaser',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on UrlAspect {
        namedUrl
      }
      ... on NamedAspect {
        title
      }
      ...on TextualAspect {
        shortTeaser
        teaser
      }
      ...${partialFragments.teaserDataFragment.name}
      ...${partialFragments.contentStatusTeaserBadgeFragment.name}
      ...${partialFragments.avWrapFragment.name}
      ...${partialFragments.linkedTitleFragment.name}
      ...${gtmDataLayerPartial.name}
    }
    ${partialFragments.teaserDataFragment.fragment()}
    ${partialFragments.contentStatusTeaserBadgeFragment.fragment()}
    ${partialFragments.avWrapFragment.fragment()}
    ${partialFragments.linkedTitleFragment.fragment()}
    ${gtmDataLayerPartial.fragment()}
    `;
  },
});

export const BigAvTextContentTeaser = ({
  content = {}, className, titleIsA, hideDescription, hideKicker,
}) => (
  <ContentTeaser
    className={cx(className, 'row')}
    content={content}
    hideKicker={hideKicker}
    hideDescription={hideDescription}
  >
    <Partials.AVWrap titleIsA={titleIsA}/>
    <Partials.MainContentArea>
      <Partials.Data>
        <Partials.ContentStatusTeaserBadge />
        <Partials.LinkedTitle aria-hidden="true" isA={titleIsA}/>
        <Partials.LinkedDescription />
      </Partials.Data>
    </Partials.MainContentArea>
  </ContentTeaser>
);

export const darkBigAvTextContentTeaserStyles = cx(
  darkPartialStyles.darkAvWrapStyles,
  darkPartialStyles.darkLinkedTitleStyles,
  darkPartialStyles.darkLinkedDescriptionStyles,
  darkPartialStyles.darkTeaserDataStyles,
);

export const StyledBigAvTextContentTeaser = BigAvTextContentTeaser;
