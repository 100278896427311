import { useEffect } from 'react';
import { HttpStatus } from './HttpStatus.jsx';
import { useFrontendConfig } from './FrontendConfigContext.jsx';
import globals from '../utils/globals';

export const ExternalRedirect = ({ to, subdomainUrl }) => {
  const { pathname, search } = to;
  const { domainName } = useFrontendConfig();
  const pathnameWithCorrectDomain = pathname.replace(domainName, subdomainUrl);
  const href = search ? `${pathnameWithCorrectDomain}${search}` : pathnameWithCorrectDomain;

  useEffect(() => {
    globals.window.location.replace(href);
  }, [href]);

  return (
    <HttpStatus code={301} contextExtension={{ url: href }}></HttpStatus>
  );
};
