import gql from 'graphql-tag';

import { StyledLinkWithArrow as LinkWithArrow, darkLinkWithArrowStyles, linkWithArrowFragment } from '../ContentBlocks/LinkWithArrow.jsx';
import { StyledVerticalColumnsList as VerticalColumnsList } from '../VerticalColumnsList';
import { makeMemoFragment } from '../../utils/graphql';

export const azIndexTopicsOverviewFragment = makeMemoFragment({
  name: 'AzIndexTopicsOverview',
  fragment() {
    return gql`fragment ${this.name} on AutoTopic {
        id
        sortTitle
        name
        ...${linkWithArrowFragment.name}
      }
      ${linkWithArrowFragment.fragment()}
    `;
  },
});

export const AzIndexTopicsOverview = ({ azIndexTopics, className }) => (
  <VerticalColumnsList className={className}>
    {azIndexTopics.map(autoTopic => (
      <li key={autoTopic.id}>
        <LinkWithArrow
          content={autoTopic}
          linkTranslation={autoTopic.sortTitle || autoTopic.name}
        />
      </li>
    ))}
  </VerticalColumnsList>
);

export const darkAzIndexTopicsOverviewStyles = darkLinkWithArrowStyles;

export const StyledAzIndexTopicsOverview = AzIndexTopicsOverview;
