import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { useParams } from '../dwouter';
import { useTranslation } from './hooks/useTranslation.jsx';
import { TextDefault } from './Text';
import languageNameTranslations from './navigation/LanguageSelector/languages.json';
import { colors } from '../utils/css';
import { StyledGlobe as GlobeIcon } from './icons';
import globals from '../utils/globals';
import { useLanguageNotification } from './hooks/useLanguageNotification';
import { ids } from '../utils/staticElementIds';
import { makeMemoFragment } from '../utils/graphql';
import { ButtonTertiarySmall } from './buttons/Button';

export const languageNotificationFragment = makeMemoFragment({
  name: 'LanguageNotification',
  fragment() {
    return gql`fragment ${this.name} on Navigation {
        id
        topStoriesNavigation {
          id
        }
      }
     `;
  },
});

export const LanguageNotification = ({ className, navigation }) => {
  const { langCode } = useParams();
  const nativeLang = languageNameTranslations.native[langCode];

  const { isLanguageNotificationShown, closeLanguageNotification } = useLanguageNotification(navigation);

  const ariaLabel = useTranslation('component.notification.language.label');
  const notificationMessage = useTranslation({
    key: 'component.notification.language.message',
    parameters: { language: nativeLang },
  });

  if (!isLanguageNotificationShown) {
    return null;
  }

  const handleOpenLanguageSelector = async () => {
    await globals.document.getElementById(ids.quickNav.navigationToggle)?.click();
    await globals.document.getElementById(ids.languageSelectorToggle)?.click();
    closeLanguageNotification();
  };
  return (
    <section className={className} aria-label={ariaLabel}>
      <TextDefault className={TextDefault.darkStyles}>{notificationMessage}</TextDefault>
      <ButtonTertiarySmall className={cx('open-lang-selector', ButtonTertiarySmall.darkStyles)} onClick={handleOpenLanguageSelector}>
        <GlobeIcon className="globe-icon"/>
        Change language
      </ButtonTertiarySmall>
    </section>
  );
};

export const StyledLanguageNotification = styled(LanguageNotification)`
  background-color: ${colors.DARK_BLUE_GREY_02};
  padding: 6px 15px;
  font-size: 1.4rem;
  display: inline-block;
  width: 100%;

  .open-lang-selector {
    display: flex;
    gap: 5px;
    float: inline-end;
    margin-inline-start: 15px;
  }
`;
