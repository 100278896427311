import { styled } from '@linaria/react';
import { cx, css } from '@linaria/core';
import { StyledFlyingFocus as FlyingFocus } from '../FlyingFocus';
import { BodyScrollContext } from '../navigation/BodyScrollContext.jsx';
import { WindowInnerWidthContextProvider } from '../WindowInnerWidthContext';
import { MobileAppContextProvider } from '../hooks/mobileAppContext';
import { useGptEffect } from '../../utils/gpt';
import { colors } from '../../utils/css';
import { SilentRedirectContextProvider } from '../hooks/silentRedirectContext.jsx';
import { GlobalComponents } from './GlobalComponents';

export const HooksDependentOnMobileAppContext = () => {
  useGptEffect();
  return null;
};

export const Page = ({ className, children }) => (
  <>
    <GlobalComponents/>
    <div className={cx(limitedPage, className)}>
      <SilentRedirectContextProvider>
        <MobileAppContextProvider>
          <BodyScrollContext>
            <WindowInnerWidthContextProvider>
              {children}
            </WindowInnerWidthContextProvider>
          </BodyScrollContext>
          <HooksDependentOnMobileAppContext/>
        </MobileAppContextProvider>
      </SilentRedirectContextProvider>
    </div>
    <FlyingFocus />
  </>
);

export const limitedPage = css`
  max-width: 1600px;
  margin: 0 auto;
`;

export const StyledPage = styled(Page)`
  background: ${colors.WHITE};
  display: grid;
`;
