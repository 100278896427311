import gql from 'graphql-tag';
import { makeMemoFragment } from '../../../utils/graphql';

export const betaLayerFragment = makeMemoFragment({
  name: 'BetaLayer',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on UrlAspect {
        classicUrl
      }
      ... on BetaLayerAspect {
        betaInfo {
          namedUrl
        }
      }
    }
    `;
  },
});
