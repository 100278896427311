import globals from './globals';

export const loadScriptPromise = async ({ url, scriptId, vendorId }) => {
  if (scriptId) {
    const existingNode = globals.document.getElementById(scriptId);
    if (existingNode) {
      return existingNode;
    }
  }
  return new Promise((resolve, reject) => {
    const scriptNode = globals.document.createElement('script');
    scriptNode.addEventListener('load', () => resolve(scriptNode));
    scriptNode.addEventListener('error', reject);
    scriptNode.setAttribute('type', 'text/javascript');
    scriptNode.setAttribute('id', scriptId);
    // TODO: remove when CMP live in native apps
    const isMobileMode = new URLSearchParams(globals.window.location.search).get('mobileApp') === 'true';
    const hasCmpImport = new URLSearchParams(globals.window.location.search).get('cmpimport') !== null;

    if (vendorId && (!isMobileMode || hasCmpImport)) {
      scriptNode.setAttribute('data-cmp-vendor', vendorId);
      scriptNode.setAttribute('data-cmp-src', url);
      scriptNode.setAttribute('data-cmp-preview', '100%x300');
      scriptNode.classList.add('cmplazyload');
    } else {
      scriptNode.setAttribute('src', url);
    }
    globals.document.head.appendChild(scriptNode);
  });
};
