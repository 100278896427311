import { useReducer, createRef } from 'react';
import { styled } from '@linaria/react';

import globals from '../utils/globals';
import {
  getPositionOfEvent,
  getXMouseMovement,
  setTranslateX,
  detachTranslateX,
} from '../utils/draggableUtils';
import { useLanguageContext } from './commons/WithGraphQLContentHandling/LanguageContext';

export const useDragReducer = ({ draggableRef, wrapperRef }) => {
  const initialState = {};
  const reducer = (state, action) => {
    switch (action.type) {
      case 'DRAG':
        return {
          ...action,
        };
      case 'RESET':
        return initialState;
      default:
        // eslint-disable-next-line fp/no-throw
        throw new Error();
    }
  };
  const { isRtl } = useLanguageContext();
  const startingSide = isRtl ? 'right' : 'left';
  const endingSide = isRtl ? 'left' : 'right';

  const isFirstIndex = wrapperBoundingClientRect => {
    const wrapperStart = Math.floor(wrapperBoundingClientRect[startingSide]);
    const draggableStart = Math.floor(draggableRef.current.children[0].getBoundingClientRect()[startingSide]);

    return (
      wrapperStart === draggableStart
    );
  };
  const isLastIndex = wrapperBoundingClientRect => {
    const lastChild = draggableRef.current.children.length - 1;
    const lastChildBoundingClientRect = draggableRef.current.children[lastChild].getBoundingClientRect();

    const draggableEnd = Math.floor(lastChildBoundingClientRect[endingSide]);
    const wrapperEnd = Math.floor(wrapperBoundingClientRect[endingSide]);

    return (
      draggableEnd === wrapperEnd
    );
  };

  const drag = e => {
    const wrapperBoundingClientRect = wrapperRef.current.getBoundingClientRect();
    const draggableBoundingClientRect = draggableRef.current.getBoundingClientRect();

    dispatch({
      type: 'DRAG',
      dragInProgress: true,
      xPositionOfEventStart: getPositionOfEvent(e).x,
      draggableOffsetStart: draggableBoundingClientRect[startingSide] - wrapperBoundingClientRect[startingSide],
      draggableTransformStart: draggableRef.current.style.transform,
      isFirstIndex: isFirstIndex(wrapperBoundingClientRect),
      isLastIndex: isLastIndex(wrapperBoundingClientRect),
    });
  };
  const reset = () => {
    dispatch({
      type: 'RESET',
    });
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return [state, { drag, reset }];
};

const getSlowedDownOffset = ({
  draggableOffsetStart, xMouseMovement, boundarySlowingFactor, movesIntoPrevSlideDirection,
}) => (
  movesIntoPrevSlideDirection
    ? (xMouseMovement + draggableOffsetStart) * boundarySlowingFactor
    : draggableOffsetStart + (xMouseMovement * boundarySlowingFactor)
);

const preventDragging = e => {
  e.preventDefault();
};

export const WithDraggable = ({
  className,
  prev,
  next,
  boundarySlowingFactor = 0.1,
  minMovementThreshold = 0.05,
  forwardWrapperRef,
  forwardDraggableRef,
  itemsOnSlideConfig = [{ res: 0, itemsOnSlide: 1 }, { res: 768, itemsOnSlide: 2 }],
  children,
  onDrag,
  onDragEndWithoutSlideChange,
}) => {
  const { isRtl } = useLanguageContext();

  const draggableRef = forwardDraggableRef || createRef();
  const wrapperRef = forwardWrapperRef || createRef();
  // eslint-disable-next-line fp/no-mutating-methods
  const itemsOnSlideConfigSorted = itemsOnSlideConfig.sort((config1, config2) => config2.res - config1.res);

  const [state, { drag, reset }] = useDragReducer({ draggableRef, wrapperRef });

  const handleDragStart = e => {
    drag(e);
  };

  const getMovesIntoPrevSlideDirection = xMouseMovement => {
    const mouseMovesRight = xMouseMovement > 0;
    return (
      isRtl ? !mouseMovesRight : mouseMovesRight
    );
  };

  const getDraggableOffsetWithBoundaries = e => {
    const xMouseMovement = getXMouseMovement(e, state.xPositionOfEventStart);
    const movesIntoPrevSlideDirection = getMovesIntoPrevSlideDirection(xMouseMovement);

    if ((state.isFirstIndex && movesIntoPrevSlideDirection) || (state.isLastIndex && !movesIntoPrevSlideDirection)) {
      return getSlowedDownOffset({
        draggableOffsetStart: state.draggableOffsetStart,
        xMouseMovement,
        boundarySlowingFactor,
        movesIntoPrevSlideDirection,
      });
    }
    return xMouseMovement + state.draggableOffsetStart;
  };

  const checkBoundaries = e => {
    const draggableOffsetWithBoundaries = getDraggableOffsetWithBoundaries(e);

    const boundaryLimit = Math.ceil(draggableRef.current.parentNode.offsetWidth * 0.1);
    const boundaryOffsetWidthLimit = (Math.abs(draggableOffsetWithBoundaries) - boundaryLimit);
    const offsetWidth = draggableRef.current.getBoundingClientRect().width - e.currentTarget.getBoundingClientRect().width;
    const { itemsOnSlide } = itemsOnSlideConfigSorted
      .find(config => globals.window.matchMedia(`screen and (min-width: ${config.res}px)`).matches) || itemsOnSlideConfigSorted[itemsOnSlideConfigSorted.length - 1];

    const slidesWidth =
      e.currentTarget.getBoundingClientRect().width * Math.floor(draggableRef.current.children.length / itemsOnSlide);

    const boundaryLimitOutOffDraggableOffsetWithBoundaries = isRtl
      ? draggableOffsetWithBoundaries >= -boundaryLimit
      : draggableOffsetWithBoundaries <= boundaryLimit;

    const isInBoundary = boundaryOffsetWidthLimit <= offsetWidth &&
    boundaryLimitOutOffDraggableOffsetWithBoundaries &&
    Math.abs(draggableOffsetWithBoundaries) <= slidesWidth + boundaryLimit;

    return ({
      isInBoundary,
      draggableOffsetWithBoundaries,
    });
  };

  const handleDragMove = e => {
    if (!state.dragInProgress) {
      return;
    }
    const { isInBoundary, draggableOffsetWithBoundaries } = checkBoundaries(e);

    if (isInBoundary) {
      setTranslateX({ target: draggableRef.current.style, x: draggableOffsetWithBoundaries });
      onDrag && onDrag({
        width: draggableRef.current.getBoundingClientRect().width,
        pos: draggableOffsetWithBoundaries,
      });
    }
  };

  const isDragDistanceShorterThanThreshold = xMouseMovement => (
    Math.abs(xMouseMovement) < (wrapperRef.current.offsetWidth * minMovementThreshold)
  );

  const handleDragEnd = e => {
    if (!state.dragInProgress) {
      return;
    }

    detachTranslateX({ target: draggableRef.current.style, originalX: state.draggableTransformStart });
    reset();

    const xMouseMovement = getXMouseMovement(e, state.xPositionOfEventStart);
    if (isDragDistanceShorterThanThreshold(xMouseMovement)) {
      onDragEndWithoutSlideChange && onDragEndWithoutSlideChange(e);
      return;
    }
    const movesIntoPrevSlideDirection = getMovesIntoPrevSlideDirection(xMouseMovement);
    if (movesIntoPrevSlideDirection) {
      prev();
    } else {
      next();
    }
  };

  return (
    <div className={className}
      onMouseDown={handleDragStart}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
      onTouchCancel={handleDragEnd}
      onDragStart={preventDragging}
      role="presentation"
      ref={wrapperRef}
    >
      {children(draggableRef)}
    </div>
  );
};

export const StyledWithDraggable = styled(WithDraggable)`
  cursor: grab;
  user-select: none;

  &:active {
    cursor: grabbing;
  }
`;
