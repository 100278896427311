import { styled } from '@linaria/react';
import { StyledHiddenHeadline as HiddenHeadline } from '../../HiddenHeadline.jsx';
import { StyledA11ySection as A11ySection } from '../../a11y/A11ySection.jsx';
import { audioHeroPlayerFragment, StyledAVPlayerHero as AVPlayerHero, videoHeroPlayerFragment } from '../../AVPlayerHero.jsx';

export const featuredAudioFragment = audioHeroPlayerFragment;
export const featuredVideoFragment = videoHeroPlayerFragment;

export const FeaturedAV = ({
  className, contents, headlineTranslation, pageSectionId,
}) => {
  const [featuredContent] = contents;
  return (
    <A11ySection
      className={className}
      pageSectionId={pageSectionId}
      sectionTitle={headlineTranslation}
    >
      <HiddenHeadline isA="h2" translation={headlineTranslation}/>
      <AVPlayerHero titleIsA="h3" content={featuredContent}/>
    </A11ySection>
  );
};

export const StyledFeaturedAV = styled(FeaturedAV)`
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;
