import { styled } from '@linaria/react';
import classnames from 'classnames';
import gql from 'graphql-tag';
import {
  colors, mediaMax, mediaMin,
} from '../../../utils/css';
import { StyledCarouselArrows as CarouselArrows } from '../../Carousel/CarouselArrows.jsx';
import { focusMenuItemsFragment, StyledFocusMenuItems as FocusMenuItems } from '../FocusMenuItems';
import { useSwipe } from '../../hooks/useSwipe';
import { cssVariables as buttonCssVariables } from '../../buttons/Button/ButtonBase.jsx';
import { useDomRef } from '../../hooks/useDomRef';
import { makeMemoFragment } from '../../../utils/graphql';
import { useHeaderStateContext } from '../HeaderStateContext';
import { useLanguageContext } from '../../commons/WithGraphQLContentHandling/LanguageContext';

export const focusMenuFragment = makeMemoFragment({
  name: 'FocusMenu',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
      focusNav {
        ...${focusMenuItemsFragment.name}
      }
    }
    ${focusMenuItemsFragment.fragment()}
    `;
  },
});

export const FocusMenu = ({
  content, className, parentClassName,
}) => {
  const [wrapperRef, parentNode] = useDomRef();
  const [childRef, childNode] = useDomRef();
  const { isFocusMenuVisible } = useHeaderStateContext();
  const { isRtl } = useLanguageContext();

  const {
    showPrevArrow, showNextArrow, movePrev, moveNext,
  } = useSwipe({ parentNode, childNode });

  if (!isFocusMenuVisible) {
    return null;
  }

  return (
    <>
      <div className={classnames('menu-wrapper', className, parentClassName)} ref={wrapperRef}>
        <FocusMenuItems focusNav={content.focusNav} forwardRef={childRef}/>
      </div>
      <CarouselArrows
        className={classnames(className, 'arrow-wrapper', { rtl: isRtl })}
        disablePrevArrow={!showPrevArrow}
        disableNextArrow={!showNextArrow}
        onPrev={() => movePrev(50)}
        onNext={() => moveNext(50)}
      />
    </>
  );
};

export const StyledFocusMenu = styled(FocusMenu)`
  &.menu-wrapper {
    display: flex;
    align-items: center;
    overflow-x: auto;
    height: 40px;
    padding: 10px 0 12px 0;
    user-select: none;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }

  ${mediaMax.sm`
    &.menu-wrapper {
      width: calc(100% - 30px);
    }
  `}
  &.arrow-wrapper {
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    height: 40px;
    overflow: hidden;

    .next, .prev {
      ${buttonCssVariables.background}: ${colors.DW_LIGHT_BLUE};
      filter: unset;
      pointer-events: initial;
      position: relative;

      svg {
        width: 40px;
        height: 40px;
        padding: 5px;
      }
    }
  }

  ${mediaMax.sm`
    &.arrow-wrapper {
      .next {
        margin-inline-end: 5px;
      }
    }
  `}

  ${mediaMin.md`
    &.menu-wrapper {
      padding-top: 0;
      padding-bottom: 0;
      height: auto;
    }
  `}

`;
