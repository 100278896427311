import { objectToQueryString } from '../../utils/url';
import { useFrontendConfig } from '../FrontendConfigContext.jsx';

export const GtmNoScriptFallback = ({ dataLayer }) => {
  const hideIframeStyles = {
    display: 'none',
    visibility: 'hidden',
  };
  const { gtmId } = useFrontendConfig();
  return (
    <noscript>
      <iframe
        title={`GTM-${gtmId}`}
        src={`https://www.googletagmanager.com/ns.html?id=GTM-${gtmId}&${objectToQueryString(dataLayer)}`}
        height="0"
        width="0"
        style={hideIframeStyles}
      />
    </noscript>
  );
};
