import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { useContentSpecificAriaLabel } from './hooks/useContentSpecificAriaLabel';
import { contentDetailKickerFragment, darkContentDetailKickerStyles, StyledContentDetailKicker as ContentDetailKicker } from './ContentDetailHeader/ContentDetailKicker.jsx';
import { StyledMediaTitle as MediaTitle } from './MediaTitle.jsx';
import { StyledVideo as Video, videoFragment } from './Video';
import { StyledAudio as Audio, audioFragment } from './Audio';
import { colors, mediaMin } from '../utils/css';
import { screenReaderOnly } from './ContentDetailHeader/ScreenReaderOnly.jsx';
import { contentAreaPaddingStyles } from './layouts/ContentMoveUpLayout/ContentArea';
import { centeredContent } from './layouts/BasicLayout';
import { ContentType } from '../utils/contentUtils';
import { makeMemoFragment } from '../utils/graphql';
import { StyledContentStatusHeaderBadge as ContentStatusHeaderBadge, contentStatusBadgeFragment } from './ContentStatusBadge.jsx';

const typeToAvPlayerHero = Object.freeze({
  [ContentType.Audio]: Audio,
  [ContentType.Video]: Video,
  [ContentType.Livestream]: Video,
});

export const videoHeroPlayerFragment = makeMemoFragment({
  name: 'VideoHeroPlayer',
  fragment() {
    return gql`fragment ${this.name} on Video {
            ...${contentStatusBadgeFragment.name}
            ...${videoFragment.name}
            ...${contentDetailKickerFragment.name}
            title
        }
        ${contentStatusBadgeFragment.fragment()}
        ${videoFragment.fragment()}
        ${contentDetailKickerFragment.fragment()}
    `;
  },
});

export const audioHeroPlayerFragment = makeMemoFragment({
  name: 'AudioHeroPlayer',
  fragment() {
    return gql`fragment ${this.name} on Audio {
            ...${contentStatusBadgeFragment.name}
            ...${audioFragment.name}
            ...${contentDetailKickerFragment.name}
            title
        }
        ${contentStatusBadgeFragment.fragment()}
        ${audioFragment.fragment()}
        ${contentDetailKickerFragment.fragment()}
    `;
  },
});

export const AVPlayerHero = ({
  children, content, className, titleIsA = 'h1',
}) => {
  const { title } = content;
  const ariaLabel = useContentSpecificAriaLabel(content.__typename, title);
  const AVTag = typeToAvPlayerHero[content.__typename];

  return (
    <>
      <div className={className}>
        <div className="av-wrapper">
          <div className="badge">
            <ContentStatusHeaderBadge content={content}/>
          </div>
          <ContentDetailKicker tabIndex={-1} className="sr-only" content={content}/>
          <MediaTitle isA={titleIsA} className="headline" aria-label={ariaLabel} contentType={content.__typename} title={title}/>
          <AVTag className="av-player" content={content} isHero/>
          <ContentDetailKicker className={cx('av-kicker', darkContentDetailKickerStyles)} aria-hidden="true" content={content}/>
        </div>
      </div>
      {children}
    </>
  );
};

export const StyledAVPlayerHero = styled(AVPlayerHero)`
  background-color: ${colors.DARK_BLUE_GREY_02};

  .av-wrapper {
    display: flex;
    flex-direction: column;
    background-color: ${colors.BLACK};
    padding-bottom: 20px;
  }

  .sr-only {
    ${screenReaderOnly}
  }

  .av-player {
    order: 1;
    margin-bottom: 20px;

    audio {
      padding-top: 56.25%;
    }
  }

  .badge {
    order: 2;
  }

  .av-kicker {
    order: 3;
    margin-bottom: 12px;
  }

  .headline {
    order: 4;
  }

  .av-kicker, .headline, .badge {
    ${contentAreaPaddingStyles}
  }

  ${mediaMin.xl`
    .av-wrapper {
      ${centeredContent}
    }
  `}
`;
