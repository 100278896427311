import gql from 'graphql-tag';
import { AutoTopicWithFallback, autoTopicFragment } from '../components/CoCo/AutoTopic';
import { WithGraphQLContentHandling, withGraphQLContentHandlingFragment } from '../components/commons/WithGraphQLContentHandling';
import { useParams } from '../dwouter';

export const autoTopicQuery = () => gql`
  query getAutoTopicPage($id: Int!, $lang: Language, $offset: Int) {
    content(id: $id, lang: $lang) {
      ...${withGraphQLContentHandlingFragment.name}
      ...${autoTopicFragment.name}
    }
  }
  ${withGraphQLContentHandlingFragment.fragment()}
  ${autoTopicFragment.fragment()}
`;

export const AutoTopicPage = () => {
  const { contentId, langCode, pageNumber } = useParams();
  const queryDef = {
    path: `${langCode}/content/auto-topic/${contentId}${pageNumber ? `/page-${pageNumber}` : ''}`,
    depts: [langCode, contentId, pageNumber],
  };
  return (
    <WithGraphQLContentHandling queryDef={queryDef}>
      {({ content }) => (
        <AutoTopicWithFallback autoTopic={content} />
      )}
    </WithGraphQLContentHandling>
  );
};
