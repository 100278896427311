import { useRouter } from '../dwouter';

export const HttpStatus = ({ code, children, contextExtension }) => {
  const router = useRouter();

  // eslint-disable-next-line fp/no-mutation
  router.staticContext.httpStatus = code;

  if (contextExtension) {
    // if we use es6 destructuring here - code will not be testable
    Object.keys(contextExtension).forEach(key => {
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      router.staticContext[key] = contextExtension[key];
    });
  }
  return children;
};
