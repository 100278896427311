import gql from 'graphql-tag';
import globals from '../../../utils/globals';
import { isChannel, isPerson } from '../../../utils/contentUtils';
import { makeMemoFragment } from '../../../utils/graphql';

const pickGtmContentTitle = content => {
  if (isChannel(content) || isPerson(content)) {
    return content.title;
  }
  return content.name;
};

export const gtmDataLayerPartial = makeMemoFragment({
  name: 'GtmDataLayer',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ...on GtmAspect {
        trackingId
        trackingLanguageCode
        trackingLanguageKey
        trackingContentType
        trackingCategories
        trackingDate
        trackingPageSID
        trackingTopicsCommaJoined
        trackingRegionsCommaJoined
      }
      ...on NamedAspect {
        name
        title
      }
      ... on MetadataAspect {
        profileTopicContent
      }
      ...on AssociationsAspect {
        subjects {
          name
        }
        topics:categories {
          originId
        }
        departments {
          name
        }
        regions {
          regionCode
          countryCode
        }
        unifiedPrograms(amount: 1) {
          name
        }
      }
      ... on UrlAspect {
        namedUrl
      }
    }
    `;
  },
});

export const mediaInfoForTrackingFragment = makeMemoFragment({
  name: 'MediaInfoForTrackingFragment',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on PlaybackResourceAspect {
        duration
      }
      ... on AssociationsAspect {
        videos {
          duration
          ...${gtmDataLayerPartial.name}
        }
        audios {
          duration
          ...${gtmDataLayerPartial.name}
        }
      }
    }
    ${gtmDataLayerPartial.fragment()}
    `;
  },
});

export const generateContentGtmInfo = ({
  content, level1Id, macaParam, pageOrigin, environment, pageNumber,
}) => {
  const {
    trackingId, trackingLanguageCode, trackingLanguageKey, trackingContentType, trackingPageSID, trackingTopicsCommaJoined,
    trackingRegionsCommaJoined, trackingDate, duration, profileTopicContent, trackingCategories = [], regions = [], subjects = [], topics,
    departments = [], unifiedPrograms = [],
  } = content;

  return {
    categoryLevel1: trackingCategories[0],
    categoryLevel2: trackingCategories[1],
    categoryLevel3: trackingCategories[2],
    contentTitle: pickGtmContentTitle(content),
    contentLanguage: trackingLanguageCode,
    dw_language_key: trackingLanguageKey,
    pageOID: trackingId,
    contentType: trackingContentType,
    date: trackingDate,
    regions: regions.map(region => `${region.regionCode}::${region.countryCode || ''}`),
    subjects: subjects.map(subject => subject.name),
    topicIds: topics?.map(topic => topic.originId),
    pageSID: trackingPageSID,
    departmentName: departments[0]?.name,
    categoryType: 1,
    displayForm: 5,
    level1ID: level1Id,
    environment,
    macaParam,
    pageUrl: `${pageOrigin}${content.namedUrl}`,
    avDuration: duration,
    avShowName: unifiedPrograms[0]?.name,
    profileTopicContent: !!profileTopicContent,
    metaRegions: trackingRegionsCommaJoined,
    metaTopics: trackingTopicsCommaJoined,
    lektionsnummer: pageNumber,
  };
};

export const exposeVideoJsVersionToWindow = playerVersion => {
  // eslint-disable-next-line fp/no-mutation
  globals.window.videoJsVersion = playerVersion;
};

export const TrackingUtils = {
  initDataLayerScript: datalayer => {
    const initialDataLayer = {
      ...datalayer,
      event: 'initDataLayer',
      // eslint-disable-next-line no-undef
      webAppVersion: VITE_VERSION,
    };

    return `
    if(!window.dataLayer) {
      window.dataLayer = [${JSON.stringify(initialDataLayer)}]
    };`.trim();
  },
  generateDataLayer: ({
    content, level1Id, macaParam, pageOrigin, environment, pageNumber,
  }) => ({
    pageData: {
      ...generateContentGtmInfo({
        content,
        level1Id,
        macaParam,
        pageOrigin,
        environment,
        pageNumber,
      }),
      embeddings: {},
    },
  }),
  pushToGoogleTagManager: ({ datalayerObj, withReset = false }) => {
    setTimeout(() => {
      if (withReset) {
        TrackingUtils.resetDataLayer();
      }
      // eslint-disable-next-line fp/no-mutating-methods
      globals.window.dataLayer?.push(datalayerObj);
    }, 0);
  },
  resetDataLayer: () => {
    // eslint-disable-next-line fp/no-mutating-methods
    globals.window.dataLayer.push(function dontUseArrowFunction() {
      this.reset();
    });
  },
};
