import { useEffect, useRef, useState } from 'react';

import { useLoadPlayerLibEffect } from './useLoadPlayerLibEffect';
import { useLoadEinbliqLibEffect } from './einbliq/useLoadEinbliqLibEffect';
import { isSafari, noop } from '../commons';
import { durationBarComponentName, videoPlayButtonComponentName } from './vjsComponentsNames';
import { useTimeout } from '../../components/hooks/useTimeout';
import { useMobileAppContext } from '../../components/hooks/mobileAppContext';
import { useFrontendConfig } from '../../components/FrontendConfigContext.jsx';

export const useVideoJsPlayer = ({ content, initCustomPlugins, playerOptions }) => {
  const { isMobileMode } = useMobileAppContext();
  const { einbliqCustomerId } = useFrontendConfig();
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [prefetchPlayer, setPrefetchPlayer] = useState(false);
  const [skeletonPlayClicked, setSkeletonPlayClicked] = useState(false);
  const allowLoadPlayer = skeletonPlayClicked || prefetchPlayer;
  const [playerReady, setPlayerReady] = useState(false);

  const { playerLib } = useLoadPlayerLibEffect({
    content,
    allowLoadPlayer,
  });
  const { initEinbliqSession } = useLoadEinbliqLibEffect({
    content,
    allowLoadEinbliq: allowLoadPlayer,
  });
  const playerRef = useRef(null);
  const playerElemRef = useRef(null);

  useTimeout({
    callback: () => setPrefetchPlayer(true),
    delay: 5000,
    conditional: !playerLib && !skeletonPlayClicked && !prefetchPlayer,
  });

  useEffect(() => {
    if (isSafari() || isMobileMode) {
      setShowSkeleton(false);
      setPrefetchPlayer(true);
    } else {
      setShowSkeleton(!skeletonPlayClicked);
    }
  }, [isMobileMode, skeletonPlayClicked]);

  useEffect(() => {
    const videoElement = playerElemRef.current;
    if (!videoElement) {
      return noop;
    }
    // make sure Video.js player is only initialized once
    if (playerLib && !playerRef.current) {
      const player = playerLib(videoElement, playerOptions);

      player.addChild(durationBarComponentName);
      player.addChild(videoPlayButtonComponentName);
      // init plugins
      player.mediaTracking();
      player.stopOtherPlayers();
      initCustomPlugins({ player, content });
      initEinbliqSession({
        player,
        content,
        einbliqCustomerId,
        isMobileMode,
      });

      player.ready(() => {
        setShowSkeleton(false);
        setPlayerReady(true);
        player.addClass('rendered');
        if (skeletonPlayClicked) {
          // player.play(); // This should be enough but does not always work - see tests
          setTimeout(() => {
            videoElement.parentElement.querySelector('.play-button').click();
          }, 10);
        }
      });

      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      playerRef.current = player;
    }
    // else you can update player here if needed e.g. on props change

    // Dispose the Video.js player when the functional component unmounts
    return () => {
      const player = playerRef.current;
      if (player && !player.isDisposed()) {
        player.dispose();
        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        playerRef.current = null;
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerElemRef, playerLib, content.id]); // watch out - tricky here, see tests

  return {
    playerElemRef,
    onSkeletonPlayClick: () => setSkeletonPlayClicked(true),
    showSpinner: !playerReady && skeletonPlayClicked,
    showSkeleton,
  };
};
