import { memo } from 'react';
import gql from 'graphql-tag';
import { cx } from '@linaria/core';

import { useParams, Redirect, useLocation } from '../dwouter';
import { StyledHeader as Header, headerFragmentFactory } from '../components/Header';
import { darkContentPageLayoutStyles, StyledContentPageLayout as ContentPageLayout } from '../components/layouts/ContentPageLayout.jsx';
import { StyledFooter as Footer, footerFragmentFactory } from '../components/Footer';
import { StyledQuickNavigationList as QuickNavigationList } from '../components/a11y/QuickNavigationList.jsx';
import { TopStoryZone } from '../components/zones/TopStoryZone/index.jsx';
import { azIndexDetailsFragment, StyledAzIndexDetails as AzIndexDetails, darkAzIndexDetailsStyles } from '../components/AzIndexDetails';
import { StyledCoCoPageHeadlineInHeader as PageHeadlineInHeader } from '../components/CoCo/CoCoPageHeadlineInHeader.jsx';
import { OnDetailPageContext } from '../components/PageEmbeddingContext.jsx';
import { useTranslation } from '../components/hooks/useTranslation.jsx';
import { BrowserOnlyBreakingBanner } from '../components/BreakingBanner';
import { PageWithData, pageWithDataFragment } from './PageWithData.jsx';
import { isValidList } from '../utils/contentUtils';
import { NotFoundPage } from './NotFoundPage.jsx';
import { GtmScriptWithDataLayer, gtmDataLayerFragment } from '../components/GoogleTagManager';
import { PageHeaderMetadata, pageHeaderMetadataFragment } from '../components/PageHeaderMetadata.jsx';
import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';

export const azIndexQuery = () => {
  const headerFragment = headerFragmentFactory();
  const footerFragment = footerFragmentFactory();
  return gql`
  query azIndexData($lang: Language!, $selectedTopicGroupId: String) {
    fakeLeadingContent: topStoriesNavigation(lang: $lang) {
      id
      language
      isLive
      ampUrl
      ...${pageWithDataFragment.name}
      ...${headerFragment.name}
      ...${footerFragment.name}
      ...${pageHeaderMetadataFragment.name}
      ...${gtmDataLayerFragment.name}
    }
    ...${azIndexDetailsFragment.name}
  }
  ${pageWithDataFragment.fragment()}
  ${gtmDataLayerFragment.fragment()}
  ${headerFragment.fragment()}
  ${footerFragment.fragment()}
  ${azIndexDetailsFragment.fragment()}
  ${pageHeaderMetadataFragment.fragment()}
`;
};

const TopStoryZoneMemo = memo(TopStoryZone);

const sharingDescriptionTranslation = topicGroupLabel => (
  {
    key: 'pages.topic_index.meta_description',
    parameters: {
      topicGroupLabel,
    },
  }
);

export const AzIndexPageNoQuery = ({
  content, azIndexTopicGroups, azIndexTopics, className,
}) => {
  const { selectedTopicGroupId } = useParams();
  const azIndexLabel = useTranslation('pages.topic_index.title');
  const [location] = useLocation();
  const namedUrl = location.pathname;
  const fakeData = {
    ...content,
    name: azIndexLabel,
    trackingContentType: 90,
    trackingId: 0,
    trackingPageSID: undefined,
    trackingDate: undefined,
    trackingCategories: ['Themenindex', selectedTopicGroupId?.toUpperCase()],
    trackingTopicsCommaJoined: '',
    trackingRegionsCommaJoined: '',
    regions: [],
    subjects: [],
    topics: [],
    departments: [],
    namedUrl,
    title: azIndexLabel,
    canonicalUrl: undefined,
    sharingDescription: useTranslation(sharingDescriptionTranslation(selectedTopicGroupId?.toUpperCase())),
    pageHeadline: azIndexLabel,
  };
  return (
    <OnDetailPageContext>
      <PageHeaderMetadata content={fakeData} />
      <GtmScriptWithDataLayer content={fakeData} />
      <QuickNavigationList content={content} />
      <div className={className}>
        <Header content={content}>
          {({ isHeaderInWhiteState }) => (isHeaderInWhiteState
            ? (
              <PageHeadlineInHeader navigation={fakeData} />
            )
            : null
          )}
        </Header>
        <BrowserOnlyBreakingBanner />
        <ContentPageLayout
          DetailZoneFn={
            () => <AzIndexDetails
              azIndexTopicGroups={azIndexTopicGroups}
              azIndexTopics={azIndexTopics}
              azIndexLabel={azIndexLabel}
            />
          }
          AlternatingColorZoneConfig={{
            compFn: () => <TopStoryZoneMemo content={content}/>,
          }}
        />
        <Footer content={content} />
      </div>
    </OnDetailPageContext>
  );
};

export const AzIndexPageNoQueryWithTheme = withTheme(AzIndexPageNoQuery)(
  cx(
    darkContentPageLayoutStyles,
    darkAzIndexDetailsStyles,
  ),
);

export const AzIndexPage = () => {
  const { langCode, selectedTopicGroupId } = useParams();
  const [{ pathname }] = useLocation();
  const queryDef = {
    path: `${langCode}/az-index/${selectedTopicGroupId}`,
    depts: [langCode, selectedTopicGroupId],
  };
  return (
    <PageWithData queryDef={queryDef} langBaseContentFn={data => data.fakeLeadingContent}>
      {data => {
        if (!isValidList(data.azIndexTopicGroups)) {
          return (
            <NotFoundPage/>
          );
        }
        const content = ({
          ...data.fakeLeadingContent,
          id: 'az-index',
        });
        const knownTopicGroup = data.azIndexTopicGroups.find(group => group.id === selectedTopicGroupId?.toLowerCase());
        if (!selectedTopicGroupId || !knownTopicGroup) {
          return <Redirect to={data.azIndexTopicGroups[0].url} />;
        }
        if (pathname !== knownTopicGroup.url) {
          return <Redirect to={knownTopicGroup.url} />;
        }
        return (
          <AzIndexPageNoQueryWithTheme content={content}
            azIndexTopicGroups={data.azIndexTopicGroups}
            azIndexTopics={data.azIndexTopics}
          />
        );
      }
      }
    </PageWithData>
  );
};
