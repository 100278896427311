import { Link as RouterLink } from '../../../dwouter';
import { useLocation } from '../../../dwouter/use-location';
import { getLocationAsObj } from '../../../dwouter/locationToUrlConverter';
import { useMobileAppContext } from '../../hooks/mobileAppContext';

export const InternalLink = ({
  to, ...restProps
}) => {
  const locationObj = getLocationAsObj(to);
  const { isMobileMode } = useMobileAppContext();
  const [fromLocation] = useLocation();
  const isSelfLink = fromLocation.pathname === locationObj.pathname;
  return (
    <RouterLink
      to={locationObj}
      isMobileMode={isMobileMode}
      replace={isSelfLink}
      scrollTop
      {...restProps}
    />
  );
};
