import gql from 'graphql-tag';

import { makeMemoFragment } from '../../../utils/graphql';

import { StyledAVCarouselTeaser as AVCarouselTeaser, avCarouselTeaserFragment, darkAVCarouselTeaserStyles } from './AVCarouselTeaser.jsx';
import { listCarouselFragment, StyledListCarousel as ListCarouselTemplate } from '../../ContentTeaserSnippets/Templates/ListCarousel.jsx';
import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';

export const avCarouselFragment = makeMemoFragment({
  name: 'AvCarousel',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ...${listCarouselFragment.name}
        ...${avCarouselTeaserFragment.name}
      }
      ${listCarouselFragment.fragment()}
      ${avCarouselTeaserFragment.fragment()}
    `;
  },
});

export const AVCarousel = props => (
  <ListCarouselTemplate
    {...props}
    teaserIsA={AVCarouselTeaser}
  />
);

export const StyledAVCarousel = extendWithClassNames(AVCarousel)(
  darkAVCarouselTeaserStyles,
);
