import { cx } from '@linaria/core';
import { StyledTeaserLinkedImage as TeaserLinkedImage, teaserLinkedImageFragment } from '../TeaserLinkedImage.jsx';
import { isImageAvailable } from '../../../../utils/imgUtils';
import { useContentTeaserContext } from '../ContentTeaserContext';

export const imageWrapFragment = teaserLinkedImageFragment;

export const ImageWrap = ({ children, className }) => {
  const { content } = useContentTeaserContext();
  return (
    isImageAvailable(content)
      ? (
        <div className={cx(className, 'teaser-image-wrap', 'col-12')}>
          <TeaserLinkedImage className="teaser-image"/>
          {children}
        </div>
      ) : null
  );
};

export const StyledImageWrap = ImageWrap;
