import { useEffect, useRef } from 'react';
import { TrackingUtils } from '../utils/TrackingUtils';

const eventName = 'onSlideChange';
export const useOnSlideChangeTracking = ({ shouldPush, eventData }) => {
  const isFirstRun = useRef(true);
  const { currentIndex } = eventData;

  useEffect(() => {
    if (isFirstRun.current) {
      // eslint-disable-next-line fp/no-mutation
      isFirstRun.current = false;
      return;
    }

    if (shouldPush) {
      TrackingUtils.pushToGoogleTagManager({
        datalayerObj: {
          event: eventName,
          eventData,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);
};
