import { useStaticInfoForGtm } from './useStaticInfoForGtm';
import { generateContentGtmInfo } from '../utils/TrackingUtils';
import { useAddEmbeddingsToGtm } from './useAddEmbeddingsToGtm';

export const useMediaTracking = content => {
  const {
    level1Id, macaParam, pageOrigin, gtmEnvironment,
  } = useStaticInfoForGtm();
  const contentDictionary = {
    [content.id]: generateContentGtmInfo({
      content,
      level1Id,
      macaParam,
      pageOrigin,
      environment: gtmEnvironment,
    }),
  };

  useAddEmbeddingsToGtm({ dictionary: contentDictionary });
};
