import { memo, useEffect, useState } from 'react';
import { styled } from '@linaria/react';
import classnames from 'classnames';
import { mediaMin, colors } from '../../../utils/css';
import { usePrev } from '../../hooks/usePrev';
import { slideTimeOutAutoPlay } from '../../hooks/useSliderLogic';
import { useTimeout } from '../../hooks/useTimeout';
import { contentAreaPaddingStyles } from '../../layouts/ContentMoveUpLayout/ContentArea';
import { globalCssVariables } from '../../Page/PageGlobalStyles';

const removeAnimationGlitch = 100;
const translationTime = slideTimeOutAutoPlay - removeAnimationGlitch;
const wasLastSlide = ({ prevSlideNumber, slidesCount }) => prevSlideNumber === slidesCount;

export const Indicator = memo(({
  className,
  slideNumber,
  slidesCount,
  isAutoplay,
}) => {
  const prevSlideNumber = usePrev(slideNumber);
  const [index, setIndex] = useState(slideNumber);
  const [resetAnimation, setResetAnimation] = useState(false);

  // Due to css transition, styling has to be reset and handled by state change to prevent animation from skipping
  useEffect(() => {
    wasLastSlide({ prevSlideNumber, slidesCount }) && isAutoplay ? setResetAnimation(true) : setIndex(slideNumber);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideNumber]);

  useTimeout({
    callback: () => {
      setIndex(slideNumber);
      setResetAnimation(false);
    },
    delay: removeAnimationGlitch,
    conditional: resetAnimation,
  });

  return (
    <div className={className}>
      {slidesCount > 50
        ? (
          <div className='indicator-fluid'>
            <div className='bar' style={{
              transform: `scaleX(${(resetAnimation ? 0 : index) / slidesCount})`,
              transition: resetAnimation ? 'none' : `transform linear ${isAutoplay
                ? translationTime : 1000
              }ms`,
            }} />
          </div>
        ) : [...Array(slidesCount)].map((e, i) => (
          <div
            key={i}
            className={
              classnames('indicator-fragment',
                {
                  active: !resetAnimation && i <= index - 1,
                })}
          >
            <div className={classnames('bar', { 'with-animation': isAutoplay })} />
          </div>
        ))}
    </div>
  );
});

export const StyledIndicator = styled(Indicator)`
  position: absolute;
  z-index: 3;
  top: 0;
  padding-top: 15px;
  ${contentAreaPaddingStyles}
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 1110px;
  display: flex;

  .indicator-fragment,
  .indicator-fluid {
    background-color: rgb(255,255,255,.3);
    height: 2px;
    width: 100%;

    .bar {
      height: 100%;
      background-color: ${colors.WHITE};
    }

  }

  .indicator-fluid {

    .bar {
      transform-origin: calc(50% - 50% * var(${globalCssVariables.flowDirection})) center;
    }

  }

  .indicator-fragment {

    .bar {
      transition: opacity 1.5s;
      opacity: 0;

      &.with-animation {
        opacity: 1;
        transform-origin: calc(50% - 50% * var(${globalCssVariables.flowDirection})) center;
        transform: scaleX(0);
      }

    }

    &.active {

      .bar {
        opacity: 1;

        &.with-animation {
          transition: transform ease-in-out ${translationTime}ms;
          transform: scaleX(1);
        }

      }

    }
    & + .indicator-fragment {
      margin-inline-start: 3px;

      ${mediaMin.lg`
        margin-inline-start: 5px;
      `}

     }
  }
`;
