import { useGlobalsContext } from '../../GlobalsContext';
import { useLocationQueryParam } from '../../hooks/useLocationQueryParam';
import { useFrontendConfig } from '../../FrontendConfigContext.jsx';

export const useStaticInfoForGtm = () => {
  const pageOrigin = useGlobalsContext().window.location.origin;
  const macaParam = useLocationQueryParam('maca');
  const { gtmLevel1Id, gtmEnvironment } = useFrontendConfig();

  return {
    level1Id: gtmLevel1Id, macaParam, pageOrigin, gtmEnvironment,
  };
};
