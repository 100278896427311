import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';
import { mediaMin } from '../../../utils/css';
import { darkContentBlockHeadlineStyles, StyledContentBlockHeadline as ContentBlockHeadline } from '../ContentBlockHeadline.jsx';
import { StyledA11ySection as A11ySection } from '../../a11y/A11ySection.jsx';

export const contentBlockPadding = {
  xs: 15,
  sm: 45,
  md: 40,
  lg: 15,
};

export const contentBlockWidth = {
  lg: 960,
  xl: 1140,
};

export const ContentBlock = ({
  className,
  headlineTranslation,
  headlineIsA,
  children,
  pageSectionId,
  isA = 'div',
}) => {
  const Tag = isA;
  return (
    <A11ySection
      className={className}
      pageSectionId={pageSectionId}
      sectionTitle={headlineTranslation}
    >
      <Tag className={cx(commonBlockSpacing, 'content-block')}>
        <ContentBlockHeadline
          isA={headlineIsA}
          translation={headlineTranslation}
        />
        {children}
      </Tag>
    </A11ySection>
  );
};

export const cssVariables = {
  maxContentBlockWidth: '--max-content-block-width',
};
export const commonBlockPadding = css`
  padding-inline: ${contentBlockPadding.xs}px;

  ${mediaMin.sm`
    padding-inline: ${contentBlockPadding.sm}px;
  `}

  ${mediaMin.md`
    padding-inline: ${contentBlockPadding.md}px;
  `}

  ${mediaMin.lg`
    padding-inline: ${contentBlockPadding.lg}px;
  `}
`;

export const commonBlockSpacing = cx(
  commonBlockPadding,
  css`
    max-width: var(${cssVariables.maxContentBlockWidth}, 100vw);
    ${mediaMin.lg`
      ${cssVariables.maxContentBlockWidth}:  ${contentBlockWidth.lg}px;
      margin-inline: auto;
    `}

    ${mediaMin.xl`
      ${cssVariables.maxContentBlockWidth}: ${contentBlockWidth.xl}px;
    `}
`,
);

export const darkContentBlockStyles = darkContentBlockHeadlineStyles;

export const StyledContentBlock = styled(ContentBlock)`
  padding-block: 25px 30px;

  *:where(.content-block) {
    content-visibility: auto;
    contain-intrinsic-block-size: auto 600px;
    max-width: var(${cssVariables.maxContentBlockWidth}, 100vw);
  }
`;
