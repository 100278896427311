/* eslint-disable jsx-a11y/media-has-caption */
import { styled } from '@linaria/react';
import gql from 'graphql-tag';

import { useMediaTracking } from '../GoogleTagManager/hooks/useMediaTracking';
import { onePixelTransparentImage } from '../../utils/imgUtils';
import { StyledPlaybackLazyPosterContainer as PlaybackLazyPosterContainer } from '../PlaybackLazyPosterContainer.jsx';
import { audioOptions } from '../../utils/video/api';
import { makeMemoFragment } from '../../utils/graphql';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { gtmDataLayerPartial } from '../GoogleTagManager/utils/TrackingUtils';
import { StyledAudioIconBackground as AudioIconBackground } from './AudioIconBackground.jsx';
import { VideoJsSkeleton } from '../VideoJsSkeleton';
import { useVideoJsPlayer } from '../../utils/video/useVideoJsPlayer';
import { screenReaderOnly } from '../ContentDetailHeader/ScreenReaderOnly.jsx';

export const audioFragment = makeMemoFragment({
  name: 'Audio',
  fragment() {
    return gql`fragment ${this.name} on Audio {
      id
      posterImageUrl
      mp3Src
      formattedDuration
      ...${gtmDataLayerPartial.name}
    }
    ${gtmDataLayerPartial.fragment()}
    `;
  },
});
const initCustomPlugins = ({ player }) => {
  player.seekButtons({
    forward: 30,
    back: 10,
  });
};

export const Audio = ({ content, className, isHero = false }) => {
  useMediaTracking(content);

  const {
    playerElemRef, onSkeletonPlayClick, showSpinner, showSkeleton,
  } = useVideoJsPlayer({
    content,
    initCustomPlugins,
    playerOptions: audioOptions,
  });

  const playButtonTitle = useTranslation('component.audio_player.play_button.label');

  return (
    <PlaybackLazyPosterContainer className={className}
      content={content}
      isHero={isHero}
      key={content.id}
      onClick={onSkeletonPlayClick}
    >
      {!content.posterImageUrl &&
        <AudioIconBackground />
      }
      <>
        <audio id={`audio-${content.id}`}
          ref={playerElemRef}
          dir="ltr"
          className="dw-player video-js vjs-fluid"
          controls
          poster={onePixelTransparentImage}
          preload="none"
          data-duration={content.formattedDuration}
          data-play-btn-title={playButtonTitle}
        >
          <source src={content.mp3Src} type="audio/mp3" />
          <p className="vjs-no-js">To play this audio please enable JavaScript, and consider upgrading to a web browser that <a href="https://videojs.com/html5-video-support/" rel="noopener noreferrer" target="_blank">supports HTML5 video</a></p>
        </audio>
        <VideoJsSkeleton
          content={content}
          showSpinner={showSpinner}
          showSkeleton={showSkeleton}
          playButtonTitle={playButtonTitle}
          onPlayClick={onSkeletonPlayClick}
        />
      </>
    </PlaybackLazyPosterContainer>
  );
};

export const StyledAudio = styled(Audio)`
  audio {
    ${screenReaderOnly}
  }
`;
