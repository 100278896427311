import { ContentTeaserContext, contentTeaserSetup } from '../../ContentTeaserSnippets/ContentTeaserPartials/ContentTeaserContext';
import { heroPictureConfig } from '../../ResponsiveDwPicture/pictureConfigs';

export const TopStoryTeaserContextProvider = ({ content, children }) => {
  const hideOpinion = contentTeaserSetup.config.hide.opinion;

  return (
    <ContentTeaserContext.Provider value={{
      config: {
        hide: {
          opinion: hideOpinion,
        },
        formatConfig: heroPictureConfig,
      },
      content,
    }}>
      {children}
    </ContentTeaserContext.Provider>
  );
};
