import gql from 'graphql-tag';
import { makeMemoFragment } from '../../utils/graphql';

export const welcomeLayerFragment = makeMemoFragment({
  name: 'WelcomeLayer',
  fragment() {
    return gql`fragment ${this.name} on WelcomeLayerAspect {
      welcomeInfo {
        namedUrl
      }
    }
    `;
  },
});
