import classnames from 'classnames';
import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';
import { colors } from '../utils/css';
import { ButtonSecondary } from './buttons/Button';

export const ChoiceChip = ({
  className,
  isSelected,
  ...restProps
}) => (
  <ButtonSecondary className={classnames(className, { selected: isSelected })} {...restProps} />
);

export const cssVariables = {
  textColorSelected: '--choice-chip-text-color-selected',
  backgroundColorSelected: '--choice-chip-background-color-selected',
};

const darkChoiceChipStyles = cx(
  ButtonSecondary.darkStyles,
  css`
    ${cssVariables.textColorSelected}: ${colors.DW_DARK_BLUE};
    ${cssVariables.backgroundColorSelected}: ${colors.WHITE};
  `,
);

// TODO linaria-next: this could be a could example how to reuse styles (Button & secondary styles)
export const StyledChoiceChip = styled(ChoiceChip)`

  &.selected {
    color: var(${cssVariables.textColorSelected}, ${colors.WHITE});
    background-color: var(${cssVariables.backgroundColorSelected}, ${colors.DW_DARK_BLUE});
  }
  && {
    border-radius: 18px;
    padding: 8px 16px;
    min-width: 50px;
    min-height: 30px;
  }
`;

StyledChoiceChip.darkStyles = darkChoiceChipStyles;
