import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import classnames from 'classnames';
import { INTERSECTION_OBSERVER_SETTINGS } from './LazyLoadDwPicture.jsx';
import { useLoadFacebookApiEffect } from '../utils/facebook';
import globals from '../utils/globals';
import { StyledCmpPreviewCompatiblePlaceholder as CmpPreviewCompatiblePlaceholder } from './CmpPreviewCompatiblePlaceholder.jsx';

const cmpPreviewClass = 'fb-embed';

// globals.window not defined during the transformation of the linaria/wyw-in-js plugin
if (globals.window) {
  // eslint-disable-next-line fp/no-mutation
  globals.window.cmp_preview_vendor_s7_class = `.${cmpPreviewClass}`;
}

export const FacebookPost = ({ className, url, embeddedClassName }) => {
  const facebookRef = useRef();
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);
  const { FB } = useLoadFacebookApiEffect({ inView });

  useEffect(() => {
    if (FB && facebookRef.current && inView) {
      FB.XFBML.parse(facebookRef.current);
    }
  }, [FB, inView]);

  return (
    <CmpPreviewCompatiblePlaceholder forwardRef={inViewRef} className={className}>
      <blockquote ref={facebookRef}
        className={classnames(cmpPreviewClass, { [embeddedClassName]: inView })}
        data-href={url}
      />
    </CmpPreviewCompatiblePlaceholder>
  );
};

export const StyledFacebookPost = FacebookPost;
