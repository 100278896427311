import { useEffect } from 'react';
import { TrackingUtils } from '../utils/TrackingUtils';

const eventName = 'addEmbeddings';

export const useAddEmbeddingsToGtm = ({ dictionary }) => {
  useEffect(() => {
    TrackingUtils.pushToGoogleTagManager({
      datalayerObj: {
        event: eventName,
        pageData: {
          embeddings: dictionary,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
