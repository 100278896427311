import { InView } from 'react-intersection-observer';

import { useTopOffsetHeaderThreshold } from '../hooks/useTopOffsetHeaderThreshold';
import { StyledAzIndexTopicGroupSelector as AzIndexTopicGroupSelector } from './AzIndexTopicGroupSelector.jsx';
import { useHeaderColorToggle } from '../hooks/useHeaderColorToggle';

export const ObservedAzIndexTopicGroupSelector = ({ className, azIndexTopicGroups }) => {
  const topOffsetHeaderThreshold = useTopOffsetHeaderThreshold();
  const { onChangeInViewListenerForHeaderSwitch } = useHeaderColorToggle();

  return (
    <InView onChange={onChangeInViewListenerForHeaderSwitch} rootMargin={topOffsetHeaderThreshold}>
      {({ ref }) => (
        <AzIndexTopicGroupSelector innerRef={ref} azIndexTopicGroups={azIndexTopicGroups} className={className}/>
      )}
    </InView>
  );
};
