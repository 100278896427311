import { cx } from '@linaria/core';

import {
  darkListDefaultUnifiedBigImageTextStyles, listDefaultUnifiedBigImageTextFragment,
  StyledListDefaultUnifiedBigImageText as ListDefaultUnifiedBigImageTextTemplate,
} from '../../ContentTeaserSnippets/Templates/ListDefaultUnifiedBigImageText.jsx';
import { isValidList } from '../../../utils/contentUtils';

export const programListFragment = listDefaultUnifiedBigImageTextFragment;

export const ProgramList = ({
  contents, headlineTranslation, pageSectionId, className,
}) => {
  if (!isValidList(contents)) {
    return null;
  }
  return (
    <ListDefaultUnifiedBigImageTextTemplate
      headlineTranslation={headlineTranslation}
      className={cx(className, darkListDefaultUnifiedBigImageTextStyles)}
      contents={contents}
      pageSectionId={pageSectionId}
      hideKicker
      hideTimeIndicator
    />
  );
};

export const StyledProgramList = ProgramList;
