import { useStaticInfoForGtm } from './useStaticInfoForGtm';
import { generateContentGtmInfo } from '../utils/TrackingUtils';
import { useAddEmbeddingsToGtm } from './useAddEmbeddingsToGtm';
import { getPlayerLinksInText } from '../../../utils/video/playbackTypeMatcher';

export const useEmbeddingsTracking = content => {
  const {
    level1Id, macaParam, pageOrigin, gtmEnvironment,
  } = useStaticInfoForGtm();

  const mediaLinkIdsInText = getPlayerLinksInText(content)
    .map(link => link.targetId);

  const mediaDictionary = [...content.videos, ...content.audios]
    .filter(media => mediaLinkIdsInText.includes(media.id))
    .reduce((acc, current) => ({
      ...acc,
      [current.id]: generateContentGtmInfo({
        content: current,
        level1Id,
        macaParam,
        pageOrigin,
        environment: gtmEnvironment,
      }),
    }), {});

  useAddEmbeddingsToGtm({ dictionary: mediaDictionary });
};
