import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { useParams } from '../../dwouter';
import { makeMemoFragment } from '../../utils/graphql';
import { StyledContentSpacer as ContentSpacer } from '../ContentSpacer.jsx';
import { HeadlineMediumBlueGrey04AndDwLightBlueNew } from '../Text/Headline.jsx';
import { StyledAzIndexTopicsOverview as AzIndexTopicsOverview, azIndexTopicsOverviewFragment, darkAzIndexTopicsOverviewStyles } from './AzIndexTopicsOverview.jsx';
import { StyledPageHeadline as PageHeadline } from '../PageHeadline.jsx';
import { ObservedAzIndexTopicGroupSelector } from './ObservedAzIndexTopicGroupSelector.jsx';
import { darkAzIndexTopicGroupSelectorStyles } from './AzIndexTopicGroupSelector.jsx';

export const azIndexDetailsFragment = makeMemoFragment({
  name: 'AzIndexDetails',
  fragment() {
    return gql`fragment ${this.name} on Query {
      azIndexTopicGroups(lang: $lang) {
        id
        url
        label
      }
      azIndexTopics (lang: $lang, selectedTopicGroupId: $selectedTopicGroupId) {
        ...${azIndexTopicsOverviewFragment.name}
      }
    }
    ${azIndexTopicsOverviewFragment.fragment()}
    `;
  },
});

export const AzIndexDetails = ({
  azIndexTopicGroups,
  azIndexTopics,
  azIndexLabel,
  className,
}) => {
  const { selectedTopicGroupId } = useParams();
  const topicGroup = azIndexTopicGroups.find(group => group.id === selectedTopicGroupId);

  return (
    <ContentSpacer
      className={className}
    >
      <PageHeadline>{azIndexLabel}</PageHeadline>
      <ObservedAzIndexTopicGroupSelector
        azIndexTopicGroups={azIndexTopicGroups}
      />
      <HeadlineMediumBlueGrey04AndDwLightBlueNew isA="h2" className="topic-index-headline">
        {topicGroup.label}
      </HeadlineMediumBlueGrey04AndDwLightBlueNew>
      <AzIndexTopicsOverview azIndexTopics={azIndexTopics} />
    </ContentSpacer>
  );
};

export const darkAzIndexDetailsStyles = cx(
  darkAzIndexTopicGroupSelectorStyles,
  HeadlineMediumBlueGrey04AndDwLightBlueNew.darkStyles,
  darkAzIndexTopicsOverviewStyles,
);

export const StyledAzIndexDetails = styled(AzIndexDetails)`
  h2 {
    text-transform: uppercase;
  }
`;
