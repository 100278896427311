/* eslint-disable default-case */
import { useEffect, useState, useRef } from 'react';
import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import classnames from 'classnames';

import { cx } from '@linaria/core';
import { Partials } from './partials';
import { slideResponsiveImageFragment } from './partials/SlideResponsiveImage.jsx';
import { slideTimeOutAutoPlay, useSliderLogic } from '../hooks/useSliderLogic';
import { colors, aspectRatioAware } from '../../utils/css';
import { keyCodes } from '../../utils/keyCodes';
import { usePrev } from '../hooks/usePrev';
import { StyledWithDraggable as WithDraggable } from '../WithDraggable.jsx';
import { getSliderPositionStyles, transition } from '../../utils/draggableUtils';
import { useToggle } from '../hooks/useToggle';
import globals from '../../utils/globals';
import { STATUS } from './partials/ControlBar.jsx';
import { ultraWideScreenCenterHeroImages } from '../../utils/ultraWideCustomCss';
import { usePageContext } from '../PageEmbeddingContext.jsx';
import { useOnSlideChangeTracking } from '../GoogleTagManager/hooks/useOnSlideChangeTracking';
import { useMediaTracking } from '../GoogleTagManager/hooks/useMediaTracking';
import { makeMemoFragment } from '../../utils/graphql';
import { hideOnPrint } from '../GlobalPrintStyles';

export const slideShowFragment = makeMemoFragment({
  name: 'SlideShow',
  fragment() {
    return gql`fragment ${this.name} on ImageGallery {
      name
      namedUrl
      teaser
      priority
      extendedGalleryImages {
        ...${slideResponsiveImageFragment.name}
      }
    }
    ${slideResponsiveImageFragment.fragment()}
    `;
  },
});

export const SlideShow = ({
  imageGallery = {}, className,
}) => {
  const { extendedGalleryImages: galleryImages, teaser } = imageGallery;
  const { isInRichText } = usePageContext();

  const minIndex = 0;
  const maxIndex = galleryImages.length - 1;
  const [sliderState, {
    prev,
    next,
    goTo,
    play,
    pause,
    progress,
    isFirstSlide,
    isLastSlide,
  }] = useSliderLogic(minIndex, maxIndex);

  const sliderTrackStyles = getSliderPositionStyles({
    childrenLength: galleryImages.length,
    currentIndex: sliderState.currentIndex,
  });
  const [isTextOverlayVisible, { toggleOnClick: toggleTextVisibility }] = useToggle({ initialState: true });
  const wasInPlayingState = usePrev(sliderState.isPlaying);
  const [galleryHasFocus, setGalleryHasFocus] = useState(false);
  const galleryEl = useRef(null);

  useMediaTracking(imageGallery);

  useOnSlideChangeTracking({
    shouldPush: !(isFirstSlide() && isLastSlide()),
    eventData: {
      maxIndex,
      currentIndex: sliderState.currentIndex,
      content: { contentId: imageGallery.id },
    },
  });

  useEffect(() => {
    globals.window.addEventListener('blur', pause, false);
    return () => globals.window.removeEventListener('blur', pause, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sliderState.isPlaying) {
      if (wasInPlayingState !== sliderState.isPlaying) {
        progress();
        return undefined;
      }
      const timeout = globals.window.setTimeout(() => {
        progress();
        isLastSlide() && pause();
      }, slideTimeOutAutoPlay);
      return () => globals.window.clearTimeout(timeout);
    }
    return undefined;
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [sliderState.isPlaying, sliderState.currentIndex]);

  useEffect(() => {
    if (sliderState.isPlaying && isLastSlide()) {
      goTo(1);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [sliderState.isPlaying]);

  const nextSlide = () => {
    next();
  };

  const prevSlide = () => {
    prev();
  };

  const handleKeyDown = e => {
    switch (e.key) {
      case keyCodes.SPACEBAR:
        e.stopPropagation();
        e.preventDefault();
        sliderState.isPlaying ? pause() : play();
        break;
      case keyCodes.ARROW_LEFT:
        e.stopPropagation();
        sliderState.isPlaying && pause();
        prevSlide();
        break;
      case keyCodes.ARROW_RIGHT:
        e.stopPropagation();
        sliderState.isPlaying && pause();
        nextSlide();
        break;
    }
  };

  useEffect(() => {
    galleryHasFocus && globals.window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      globals.window.removeEventListener('keydown', handleKeyDown, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderState.isPlaying, galleryHasFocus]);

  useEffect(() => {
    const handleFocusChange = e => {
      const isGalleryClicked = e.composedPath().find(el => el === galleryEl.current);
      setGalleryHasFocus(!!isGalleryClicked);
    };

    globals.window.addEventListener('click', handleFocusChange);

    return () => {
      globals.window.removeEventListener('click', handleFocusChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFocus = () => {
    setGalleryHasFocus(true);
  };

  const handleBlur = () => {
    setGalleryHasFocus(false);
  };

  return (
    galleryImages.length > 0 && (
      <div ref={galleryEl} onFocusCapture={handleFocus} onBlurCapture={handleBlur} className={cx(hideOnPrint, className)} data-tracking-name="slide-show">
        <WithDraggable className="slider-container" next={next} prev={prev} itemsOnSlideConfig={[{ res: 0, itemsOnSlide: 1 }]}>
          {
            draggableRef => (
              <section ref={draggableRef} className={classnames('slider', { 'is-hero': !isInRichText })} style={sliderTrackStyles}>
                {galleryImages.map((galleryImage, index) => {
                  const imagesClassName = 'slide-img';
                  const isCurrentIndex = index === sliderState.currentIndex;
                  const styleForFixedWidth = {
                    width: `${100 / galleryImages.length}%`,
                  };
                  return (
                    index === 0
                      ? (
                        <Partials.CoverImage
                          galleryImage={galleryImage}
                          title={imageGallery.name}
                          teaser={teaser}
                          priority={imageGallery.priority}
                          className={imagesClassName}
                          key={index}
                          style={styleForFixedWidth}
                          aria-hidden={!isCurrentIndex}
                        />
                      )
                      : <Partials.SlideImage
                        galleryImage={galleryImage}
                        onTextOverlayToggle={() => {
                          toggleTextVisibility();
                          pause();
                        }}
                        isTextOverlayOpened={isTextOverlayVisible}
                        className={imagesClassName}
                        key={index}
                        isCurrentIndex={isCurrentIndex}
                        style={styleForFixedWidth}
                        aria-hidden={!isCurrentIndex}
                      />
                  );
                })}
              </section>
            )
          }
        </WithDraggable>
        <Partials.Indicator
          slideNumber={sliderState.currentIndex}
          slidesCount={maxIndex}
          className={classnames('indicator', { show: !isFirstSlide() })}
          isAutoplay={sliderState.isPlaying}
        />
        <Partials.SlideArrows
          disablePrevArrow={isFirstSlide()}
          disableNextArrow={isLastSlide()}
          onPrev={() => { sliderState.isPlaying && pause(); prevSlide(); }}
          onNext={() => { sliderState.isPlaying && pause(); nextSlide(); }}
        />
        <Partials.ControlBar
          slideNumber={sliderState.currentIndex}
          slidesCount={maxIndex}
          playingState={sliderState.isPlaying ? STATUS.play : `${isFirstSlide() ? STATUS.init : STATUS.pause}`}
          onPlayPause={sliderState.isPlaying ? pause : play}
        />
      </div>
    )
  );
};

export const StyledSlideShow = styled(SlideShow)`
  position: relative;
  margin-inline: auto;
  overflow: hidden;

  .slider-container {
    margin: 0 auto;
    overflow: hidden;
    background-color: ${colors.BLACK};
  }

  .slider {
    transition: ${transition};
    display: flex;
  }

  .slide-img {
    position: relative;
    overflow: hidden;
  }

  .text-overlay {

    & * {
      overflow-wrap: break-word;
    }

    width: 100%;
    position: absolute;
    bottom: -1px;
    z-index: 1;
    padding: 0 0 10px;
    display: grid;
    max-height: 80%;
    overflow: hidden;

    :nth-last-child(3) {
      padding: 0;
      padding-bottom: 15px;
    }
  }

  .indicator {
    opacity: 0;
    margin-top: -40px;
    transition: all 400ms cubic-bezier(0.91, 0, 1, 1);

    &.show {
      margin-top: 0;
      opacity: 1;
      transition: all 400ms cubic-bezier(0, 0, 0.09, 1) 400ms;
    }
  }

  ${aspectRatioAware.xl.ultraWide`
    .is-hero .slide-img {
      height: calc(100vh - 130px);
      overflow: hidden;
      max-height: 700px;
      ${ultraWideScreenCenterHeroImages}
    }
  `}
`;
