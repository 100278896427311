import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import {
  observedContentDetailActionsFragment,
  ObservedContentDetailActions,
  darkObservedContentDetailActionStyles,
} from './ObservedContentDetailActions.jsx';
import {
  contentDetailFooterFragment,
  darkContentDetailFooterStyles,
  StyledContentDetailFooter as ContentDetailFooter,
} from './ContentDetailFooter';
import {
  StyledMediaDetailsHeader as MediaDetailsHeader,
  mediaDetailsHeaderFragment,
  darkMediaDetailHeaderStyles,
} from './ContentDetailHeader/MediaDetailsHeader.jsx';
import {
  contentDetailRichTextFragment,
  darkDetailRichTextStyles,
  StyledContentDetailRichText as ContentDetailRichText,
} from './ContentDetailRichText';
import { StyledBasicLayout as BasicLayout } from './layouts/BasicLayout';
import { audioOpenGraphFragment, AudioOpenGraphMetadata } from './AudioOpenGraphMetadata.jsx';
import { darkGptSlotStyles, StyledBeforeRichTextGptSlot as BeforeRichTextGptSlot } from './GptSlot';
import { mediaTopSlotConfig } from './GptSlot/slotConfigs';
import { makeMemoFragment } from '../utils/graphql';
import { StyledAVPlayerHero as AudioPlayerHero, audioHeroPlayerFragment } from './AVPlayerHero.jsx';

export const audioDetailsFragment = makeMemoFragment({
  name: 'AudioDetails',
  fragment() {
    return gql`fragment ${this.name} on Audio {
            ...${audioHeroPlayerFragment.name}
            ...${mediaDetailsHeaderFragment.name}
            ...${contentDetailRichTextFragment.name}
            ...${observedContentDetailActionsFragment.name}
            ...${contentDetailFooterFragment.name}
            ...${audioOpenGraphFragment.name}
            title
        }
        ${audioHeroPlayerFragment.fragment()}
        ${mediaDetailsHeaderFragment.fragment()}
        ${contentDetailRichTextFragment.fragment()}
        ${observedContentDetailActionsFragment.fragment()}
        ${contentDetailFooterFragment.fragment()}
        ${audioOpenGraphFragment.fragment()}
    `;
  },
});

export const AudioDetails = ({ content = {}, className }) => (
  <BasicLayout
    className={className}
    heroCmpProps={{ content }}
    HeroCmp={AudioPlayerHero}
  >
    <AudioOpenGraphMetadata content={content}/>
    <MediaDetailsHeader content={content}/>
    <ObservedContentDetailActions content={content} />
    <BeforeRichTextGptSlot slotConfig={mediaTopSlotConfig}/>
    <ContentDetailRichText content={content}/>
    <ContentDetailFooter content={content}/>
  </BasicLayout>
);

export const darkAudioDetailsStyles = cx(
  darkMediaDetailHeaderStyles,
  darkObservedContentDetailActionStyles,
  darkDetailRichTextStyles,
  darkContentDetailFooterStyles,
  darkGptSlotStyles,
);

export const StyledAudioDetails = AudioDetails;
