import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { mediaMin } from '../utils/css';

import { HeadlineSemiBoldBlueGrey04AndBlueGrey03 } from './Text/Headline.jsx';
import { commonBlockSpacing } from './ContentBlocks/ContentBlock';

export const PageHeadline = ({ className, ...otherProps }) => (
  <HeadlineSemiBoldBlueGrey04AndBlueGrey03 { ...{ ...otherProps, isA: 'h1', className: cx(className, commonBlockSpacing) } } />
);

export const StyledPageHeadline = styled(PageHeadline)`
  margin-block: 0;
  font-size: 2.2rem;
  padding-top: 10px;
  padding-bottom: 10px;


  ${mediaMin.md`
    font-size: 2.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
  `}

  ${mediaMin.lg`
    font-size: 3rem;
    padding-top: 15px;
    padding-bottom: 15px;
  `}
`;
