import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import classnames from 'classnames';
import { cx } from '@linaria/core';
import { colors, mediaMin } from '../../utils/css';

import {
  contentDetailActionsSocialMediaIconLinkFragment,
  StyledContentDetailActionsSocialMediaIconLink as ContentDetailActionsSocialMediaIconLink,
  darkContentDetailActionsSocialMediaIconLinkStyles,
} from '../ContentDetailActionsSocialMediaIconLink.jsx';
import { StyledCopyTextButton as CopyTextButton, darkCopyButtonStyles } from '../buttons/CopyTextButton.jsx';
import { HeadlineSemiBoldDwDarkBlueAndWhite } from '../Text/Headline.jsx';
import { useGlobalsContext } from '../GlobalsContext';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { useToggle } from '../hooks/useToggle';
import { headerHeight } from '../Header/headerUtils';
import { makeMemoFragment } from '../../utils/graphql';
import { hasPermalinkSupport } from '../../utils/contentUtils';
import { StyledMoreActionsButton as MoreActionsButton, darkMoreActionsButtonStyles } from './MoreActionsButton.jsx';
import { hideOnPrint } from '../GlobalPrintStyles';

export const contentDetailActionsFragment = makeMemoFragment({
  name: 'ContentDetailActions',
  fragment() {
    return gql`fragment ${this.name} on Content {
          ... on ModelAspect {
            isLive
            id
          }
          ... on UrlAspect {
            permaLinkUrl
            namedUrl
          }
          ... on NamedAspect {
            title
          }
          ... on SocialMediaAspect {
            socialMediaConfig {
              id
              ...${contentDetailActionsSocialMediaIconLinkFragment.name}
            }
          }
        }
      ${contentDetailActionsSocialMediaIconLinkFragment.fragment()}
    `;
  },
});

export const ContentDetailActions = ({
  content = {},
  isInHeader = false,
  className,
  innerRef,
}) => {
  const {
    title, isLive, socialMediaConfig, permaLinkUrl, namedUrl,
  } = content;
  const { origin } = useGlobalsContext().window.location;
  const contentSharingUrl = `${origin}${namedUrl}`;
  const [isOpen, { toggleOnClick: toggleIsOpen }] = useToggle();
  const buttonTitle = useTranslation(`component_detail.detail_actions.toggle_button.label.${isOpen ? 'close' : 'open'}`);

  const isInHeaderClassName = isInHeader ? 'in-header' : 'in-line';
  const [firstSM, secondSM, ...moreSocialMedia] = socialMediaConfig || []; // eslint-disable-line fp/no-rest-parameters
  const toggableClassName = isOpen ? 'open' : 'closed';
  const tabIndexForToggableButtons = isOpen ? 0 : -1;
  const trackingName = isInHeader ? 'sharing-icons-header' : 'sharing-icons-inline';

  return (
    <section
      ref={innerRef}
      className={classnames(className, hideOnPrint, isInHeaderClassName, toggableClassName)}
      data-tracking-name={trackingName}
    >
      <div className="always-visible">
        <ContentDetailActionsSocialMediaIconLink socialMediaEntry={firstSM} contentUrl={contentSharingUrl}/>
        <ContentDetailActionsSocialMediaIconLink socialMediaEntry={secondSM} contentUrl={contentSharingUrl}/>
        <MoreActionsButton
          className="more-icon icon"
          onClick={toggleIsOpen}
          title={buttonTitle}
          isOpen={isOpen}
        />
        {
          isInHeader && title
            ? (
              <>
                <span className="separator"/>
                <HeadlineSemiBoldDwDarkBlueAndWhite className="content-title">
                  {title}
                </HeadlineSemiBoldDwDarkBlueAndWhite>
              </>
            )
            : null
        }
      </div>
      <div className={`more ${toggableClassName}`}>
        {moreSocialMedia.map(socialMediaEntry => (
          <ContentDetailActionsSocialMediaIconLink
            socialMediaEntry={socialMediaEntry}
            key={socialMediaEntry.id}
            isHidden={!isOpen}
            contentUrl={contentSharingUrl}
            tabIndex={tabIndexForToggableButtons}
          />
        ))}
      </div>
      <div className={`copy-button-wrapper ${toggableClassName}`}>
        <CopyTextButton
          textToCopy={isLive && hasPermalinkSupport(content)
            ? permaLinkUrl
            : contentSharingUrl}
          className={`${toggableClassName}`}
          tabIndex={tabIndexForToggableButtons}
        />
      </div>
    </section>
  );
};

export const darkContentDetailActionStyles = cx(
  darkContentDetailActionsSocialMediaIconLinkStyles,
  darkMoreActionsButtonStyles,
  darkCopyButtonStyles,
);

export const inlineStyles = `
  &.in-line {
    max-width: 300px;
    margin: 20px auto;
    
    &.open {
      row-gap: 8.5px;
    }
    
    :is(.always-visible, .more) {
      column-gap: 6.25px;
    }
    a,.icon {
      width: 55px;
    }
  }
`;

export const inlineSmStyles = `
  &.in-line {
    max-width: 340px;
    :is(.always-visible, .more) {
      column-gap: 10px;
    }

    a,.icon {
      width: 60px;
    }

    .more-icon svg {
      width: 45px;
    }

  }
`;

export const inlineMdStyles = `
  &.in-line {
    max-width: 100%;
    align-items: flex-start;

    a,.icon {
     width: 40px;
    }

    .more-icon svg {
      width: 25px;
    }
  }
`;

export const alwaysVisibleStyles = `
  .always-visible {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: inherit;
    justify-content: center;
    align-items: center;
  }
`;

export const alwaysVisibleMdStyles = `
  .always-visible {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const aTagIconStyles = `
  a, .icon {
    width: 40px;
    flex-shrink: 1;
  }
`;

// TODO linaria-next css``
export const sharingIconsInHeaderStyles = `
  .sharing-icons {
    width: 100%;
  }
`;

export const StyledContentDetailActions = styled(ContentDetailActions)`
  display: flex;
  flex-direction: column;
  min-height: inherit;

  ${alwaysVisibleStyles}

  ${aTagIconStyles}

  .content-title,
  .separator,
  .print-icon {
    display: none;
  }

  &.in-header {
    width: 100%;
    &.open {
      row-gap: 7.5px;
    }
    transition: margin-bottom 170ms cubic-bezier(0, 0, 0.09, 1) 33ms;

    .always-visible {
      column-gap: 5px;
      min-height: ${headerHeight.xs.thin}px;
    }

    &.open {
      margin-bottom: 15px;
    }

    .more {
      a,.icon {
        text-align: center;
        width: 20%;
        svg {
          max-width: 40px;
        }
      }
    }

    .more-icon svg {
      width: 25px;
    }
  }

  ${inlineStyles}

  .more {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;
    &.open {
      max-height: 500px;
      transition: max-height 170ms cubic-bezier(0, 0, 0.09, 1) 33ms, opacity 170ms cubic-bezier(0.43, 0, 0.09, 1) 33ms;
    }
    &.closed {
      max-height: 0;
      opacity: 0;
      transition: max-height 150ms cubic-bezier(0, 0, 0.09, 1) 50ms, opacity 100ms cubic-bezier(0.43, 0, 0.09, 1) 50ms;
    }
  }

  .copy-button-wrapper {
    overflow: hidden;
    & > div[role="button"] {
      width: 100%;
    }
    &.open {
      margin-top: 4px;
      max-height: 100px;
      transition: margin-top 215ms cubic-bezier(0.43, 0, 0.09, 1) 33ms, max-height 215ms cubic-bezier(0.43, 0, 0.09, 1) 33ms, opacity 200ms cubic-bezier(0.43, 0, 0.09, 1) 75ms;
    }
    &.closed {
      max-height: 0;
      opacity: 0;
      transition: margin-top 170ms cubic-bezier(0.43, 0, 0.09, 1), max-height 170ms cubic-bezier(0.43, 0, 0.09, 1), opacity 170ms cubic-bezier(0.43, 0, 0.09, 1);
    }
  }

  ${mediaMin.sm`
    &.in-header {
      .more {
        column-gap: 5px;
        a,.icon {
          width: 40px;
        }
      }
    }

    ${inlineSmStyles}
  `}

  ${mediaMin.md`
    &.in-header {
      .copy-button-wrapper {
        align-self: flex-start;
      }

      .always-visible {
         min-height: ${headerHeight.md}px;
      }
    }

    ${inlineMdStyles}

    ${alwaysVisibleMdStyles}

    .print-icon {
      display: inline-block;
    }

    .content-title {
      display: block;
      font-size: 1.8rem;
      white-space: nowrap;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    .separator {
      display: inline-block;
      margin-inline-start: 1.2%;
      margin-inline-end: 3.6%;
      height: 28px;
      border-inline-end: 1px solid ${colors.BLUE_GREY_02};
    }
  `}

  ${mediaMin.lg`
    &.in-header {
      .always-visible {
        min-height: ${headerHeight.lg}px;
      }
    }

    .separator {
      margin-inline-start: 1.4%;
      margin-inline-end: 2.6%;
    }
  `}

  ${mediaMin.xl`
    .separator {
      margin-inline-start: 8px;
      margin-inline-end: 19px;
    }
  `}
`;
