import { invert } from './commons';
import globals from './globals';

const typeToUrlPrefixMap = {
  ARTICLE: 'a',
  NAVIGATION: 's',
  AUTO_TOPIC: 't',
  IMAGE_GALLERY: 'g',
  LIVEBLOG: 'live',
  CLASSIC_AV: 'av',
};
const urlPrefixToTypeMap = invert(typeToUrlPrefixMap);

export const urlPrefixToType = typeId => urlPrefixToTypeMap[typeId] || typeId?.toUpperCase();

export const urlHostNameFromString = ({ url }) => {
  try {
    return new URL(url).hostname;
  } catch (e) {
    console.log(`error parsing url : ${url}\n`, e);
    return false;
  }
};

export const getQueryParamValueFrom = ({ from = globals.window.location.search, value }) => new URLSearchParams(from).get(value);
export const objectToQueryParams = obj => new URLSearchParams(obj).toString();

const relativeUrlRegExp = /^\//;
export const isExternalLink = url => !relativeUrlRegExp.test(url);
const dwUrlRegExp = /^(?<protocol>[a-zA-Z])*:\/\/(?<subdomain>[a-zA-Z])*(?<environment>-[a-zA-Z0-9]*)?\.dw\.com/;
export const isExternalDwLink = url => dwUrlRegExp.test(url);

export const getUrlWithFallbackToHomePage = ({ namedUrl = '/' } = {}) => namedUrl;

const createKeyPath = (path, key) => (path != null ? `${path}[${key}]` : key);

const flattenNestedObject = (obj, path) => Object.keys(obj)
  .reduce((acc, key) => {
    if (!obj[key]) {
      return acc;
    }
    if (typeof obj[key] === 'object') {
      // eslint-disable-next-line fp/no-mutating-assign
      return Object.assign(acc, flattenNestedObject(obj[key], createKeyPath(path, key)));
    }
    // eslint-disable-next-line fp/no-mutating-assign
    return Object.assign(acc, { [createKeyPath(path, key)]: obj[key] });
  }, {});

export const objectToQueryString = obj => {
  const flattenedObject = flattenNestedObject(obj);
  return Object.entries(flattenedObject).map(entries => entries.join('=')).join('&');
};

export const getPathnameFromUrlPath = path => path.split('?')[0];
export const getSearchFromUrlPath = path => {
  const searchParams = path.split('?')[1];
  if (!searchParams) {
    return '';
  }
  return `?${searchParams}`;
};
export const getLangCodeFromUrlPath = path => path.split('/')[(path[0] === '/' ? 1 : 3)];
