import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { StyledArrowLineLeft } from '../icons/carousel/ArrowLineLeft.jsx';
import { StyledArrowLineRight } from '../icons/carousel/ArrowLineRight.jsx';
import { mediaMin } from '../../utils/css';
import { useTranslation } from '../hooks/useTranslation.jsx';
import {
  slideInLeft, slideOutLeft, slideInRight, slideOutRight, arrowStyles, cssVariables as slideArrowsCssVariables,
} from '../slideshow/partials/SlideArrows.jsx';
import { ButtonWithIconOnly } from '../buttons/Button';

export const CarouselArrows = ({
  className,
  onPrev,
  onNext,
  disablePrevArrow,
  disableNextArrow,
}) => {
  const prevArrowTitle = useTranslation('content_block.top_story.more_on_top_story.previous_button.label');
  const nextArrowTitle = useTranslation('content_block.top_story.more_on_top_story.next_button.label');
  return (
    <div className={cx(className, arrowStyles)}>
      <ButtonWithIconOnly
        className={cx('prev', disablePrevArrow && slideOutLeft, slideInLeft)}
        onClick={onPrev}
        disabled={disablePrevArrow}
        title={prevArrowTitle}
      >
        <StyledArrowLineLeft/>
      </ButtonWithIconOnly>
      <ButtonWithIconOnly
        className={cx('next', disableNextArrow && slideOutRight, slideInRight)}
        onClick={onNext}
        disabled={disableNextArrow}
        title={nextArrowTitle}
      >
        <StyledArrowLineRight/>
      </ButtonWithIconOnly>
    </div>
  );
};

export const darkCarouselArrowsStyles = ButtonWithIconOnly.darkStyles;

export const StyledCarouselArrows = styled(CarouselArrows)`
  width: 100%;

  .prev, .next {
    transform: translate(0, -50%);
    top: 50%;

    svg {
      width: 40px;
      height: 40px;
    }
  }


  ${slideArrowsCssVariables.iconWidth}: 40px;

  ${mediaMin.sm`
    ${slideArrowsCssVariables.iconWidth}: 50px;
    .prev, .next {
      svg {
        width: 50px;
        height: 50px;
      }
    }
  `}
`;
