import { cx } from '@linaria/core';
import { darkContentBlockStyles } from '../ContentBlocks/ContentBlock';
import { darkPartialStyles } from '../ContentTeaserSnippets/ContentTeaserPartials';
import { darkGptSlotStyles } from '../GptSlot';

import { withTheme } from '../higherOrderComponents/withTheme.jsx';
import { StyledCoCoComponentBackgroundSelector, darkCoCoComponentBackgroundSelectorStyles } from './CoCoComponentBackgroundSelector.jsx';
import { darkHubStyles } from '../ContentBlocks/Types/Hub.jsx';

export const CoCoComponentBackgroundSelectorWithTheme = withTheme(StyledCoCoComponentBackgroundSelector)(
  cx(
    darkCoCoComponentBackgroundSelectorStyles,
    Object.values(darkPartialStyles).join(' '),
    darkContentBlockStyles,
    darkHubStyles,
    darkGptSlotStyles,
  ),
);
