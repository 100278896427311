import gql from 'graphql-tag';
import { cx } from '@linaria/core';

import { StyledHeader as Header, headerFragmentFactory } from '../components/Header';
import { StyledContentPageLayout as ContentPageLayout, darkContentPageLayoutStyles } from '../components/layouts/ContentPageLayout.jsx';
import { StyledFooter as Footer, footerFragmentFactory } from '../components/Footer';

import { OnDetailPageContext } from '../components/PageEmbeddingContext.jsx';
import { PageHeaderMetadata, pageHeaderMetadataFragment } from '../components/PageHeaderMetadata.jsx';
import { ImageSharingMetadata, imageSharingMetadataFragment } from '../components/ImageSharingMetadata.jsx';
import { GtmScriptWithDataLayer, gtmDataLayerFragment } from '../components/GoogleTagManager';
import { jsonLdScriptFragment, JsonLdScript } from '../components/JsonLdScript.jsx';

import { liveblogPostSharingDetailsFragment, StyledLiveblogPostSharingDetails as LiveblogPostSharingDetails, darkLiveblogPostSharingDetailsStyles } from '../components/LiveblogPostSharingDetails';
import { WithGraphQLContentHandling } from '../components/commons/WithGraphQLContentHandling';
import { makeMemoFragment } from '../utils/graphql';
import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';
import { useParams } from '../dwouter';

export const liveblogPostSharingPageFragment = makeMemoFragment({
  name: 'LiveblogPostSharingPage',
  fragment() {
    const headerFragment = headerFragmentFactory();
    const footerFragment = footerFragmentFactory();
    return gql`fragment ${this.name} on LiveblogElement {
      ...${pageHeaderMetadataFragment.name}
      ...${liveblogPostSharingDetailsFragment.name}
      ...${jsonLdScriptFragment.name}
      ...${headerFragment.name}
      ...${footerFragment.name}
      ...${imageSharingMetadataFragment.name}
      ...${gtmDataLayerFragment.name}
    }
    ${pageHeaderMetadataFragment.fragment()}
    ${liveblogPostSharingDetailsFragment.fragment()}
    ${jsonLdScriptFragment.fragment()}
    ${headerFragment.fragment()}
    ${footerFragment.fragment()}
    ${imageSharingMetadataFragment.fragment()}
    ${gtmDataLayerFragment.fragment()}
    `;
  },
});

export const LiveblogPostSharingPageNoQuery = ({
  content,
}) => (
  <OnDetailPageContext>
    <PageHeaderMetadata content={content} />
    <ImageSharingMetadata content={content} />
    <GtmScriptWithDataLayer content={content} />
    <JsonLdScript content={content}/>
    <Header content={content} />
    <ContentPageLayout
      DetailZoneFn={
        () => (
          <LiveblogPostSharingDetails
            content={content}
          />
        )
      }
    />
    <Footer content={content} />
  </OnDetailPageContext>
);

export const LiveblogPostSharingPageNoQueryWithTheme = withTheme(LiveblogPostSharingPageNoQuery)(
  cx(
    darkContentPageLayoutStyles,
    darkLiveblogPostSharingDetailsStyles,
  ),
);

export const liveblogPostSharingPageQueryOpts = {
  name: 'getLiveblogPostSharing',
  fragmentDef: liveblogPostSharingPageFragment,
};

export const LiveblogPostSharingPage = () => {
  const { contentId, langCode } = useParams();
  const queryDef = {
    path: `${langCode}/content/liveblog-post/${contentId}`,
    depts: [langCode, contentId],
  };
  return (
    <WithGraphQLContentHandling queryDef={queryDef}>
      {({ content }) => (
        <LiveblogPostSharingPageNoQueryWithTheme
          content={content}
        />
      )}
    </WithGraphQLContentHandling>
  );
};
