import { useState, useEffect, useRef } from 'react';
import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import globals from '../../utils/globals';

import { BrowserOnly } from '../BrowserOnly.jsx';
import {
  BetaLayer,
  LiveWelcomeLayer,
} from './SuspendedInfoLayerPartials';
import { betaLayerFragment } from './BetaLayer/betaLayerFragment';
import { welcomeLayerFragment } from './liveWelcomeLayerFragment';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useTimeout } from '../hooks/useTimeout';
import { useMobileAppContext } from '../hooks/mobileAppContext';
import { makeMemoFragment } from '../../utils/graphql';
import { hideOnPrint } from '../GlobalPrintStyles';

export const infoLayerFragment = makeMemoFragment({
  name: 'InfoLayer',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          isLive
          showLiveWelcomeLayer
        }
        ...${betaLayerFragment.name}
        ...${welcomeLayerFragment.name}
      }
      ${betaLayerFragment.fragment()}
      ${welcomeLayerFragment.fragment()}
     `;
  },
});

const layersConfig = Object.freeze({
  beta: {
    LayerTag: BetaLayer,
    layerStorageKey: 'showBetaLayer',
  },
  liveWelcome: {
    LayerTag: LiveWelcomeLayer,
    layerStorageKey: 'showLiveWelcomeLayer',
  },
});

export const InfoLayer = ({ className, content }) => {
  const { LayerTag, layerStorageKey } = content.isLive ? layersConfig.liveWelcome : layersConfig.beta;
  const [showLayer, setShowLayer] = useLocalStorage(layerStorageKey);

  const isLayerInitiallyCollapsed = useRef(showLayer);
  const [startLayerTimer, setStartLayerTimer] = useState(false);

  const startTimerToShowLayer = () => {
    setStartLayerTimer(true);
  };

  useEffect(() => {
    !showLayer && globals.window.addEventListener('scroll', startTimerToShowLayer, { passive: true });

    return () => {
      globals.window.removeEventListener('scroll', startTimerToShowLayer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTimeout({
    callback: () => {
      setShowLayer(true);
    },
    delay: 3000,
    conditional: startLayerTimer,
  });

  const { isMobileMode } = useMobileAppContext();

  if (isMobileMode) {
    return null;
  }

  return (
    <BrowserOnly>
      <div className={cx(hideOnPrint, className)}>
        {showLayer && (!content.isLive || content.showLiveWelcomeLayer)
          ? (
            <LayerTag
              content={content}
              isCollapsed={isLayerInitiallyCollapsed.current}
            />
          )
          : null
        }
      </div>
    </BrowserOnly>
  );
};

export const StyledInfoLayer = styled(InfoLayer)`
  display: flex;
  flex-direction: row-reverse;
`;
