import { cloneElement, useEffect } from 'react';
import { styled } from '@linaria/react';
import classnames from 'classnames';

import { StyledWithDraggable as WithDraggable } from '../WithDraggable.jsx';
import { StyledA11yAriaLive as A11yAriaLive } from '../a11y/A11yAriaLive.jsx';
import {
  colors, mediaMin, resolutions,
} from '../../utils/css';
import { StyledSlider as Slider } from './Slider.jsx';
import { useWindowInnerWidth } from '../WindowInnerWidthContext/useWindowInnerWidth';
import { useSliderLogic } from '../hooks/useSliderLogic';

import { darkCarouselArrowsStyles, StyledCarouselArrows as CarouselArrows } from './CarouselArrows.jsx';
import { Partials } from '../slideshow/partials';

export const Carousel = ({ className, children = [], onDragEndWithoutSlideChange }) => {
  const minIndex = 0;
  const maxIndex = children.length - 1;

  const [state, { goTo, next, prev }] = useSliderLogic(minIndex, maxIndex);

  const hasPositionIndicators = children.length > 1;
  const hasPositionIndicatorsOnMdPlus = children.length > 2;
  const innerWidth = useWindowInnerWidth({ isAlwaysReset: true });
  const resMaxSm = innerWidth > resolutions.max.sm;
  const itemsPerSlide = resMaxSm ? 2 : 1;
  const isCurrent = index => index === state.currentIndex;
  const isCurrentIndexBiggerThanLastVisibleIndex = state.currentIndex >= Math.floor(maxIndex / itemsPerSlide);

  const nextVisibleIndex = () => {
    if (!isCurrentIndexBiggerThanLastVisibleIndex) {
      next();
    }
  };

  useEffect(() => {
    if (isCurrentIndexBiggerThanLastVisibleIndex) {
      goTo(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerWidth]);

  const ariaLiveTranslation = {
    key: 'content_block.top_story.more_on_top_story.paging_live_region.label',
    parameters: {
      currentPage: state.currentIndex + 1,
      totalPages: Math.ceil(children.length / itemsPerSlide),
    },
  };

  const isOnCurrentSlide = index => {
    const isSmallerThanNext = index < itemsPerSlide * (state.currentIndex + 1);
    const isBiggerThanCurrent = index >= itemsPerSlide * state.currentIndex;
    return isSmallerThanNext && isBiggerThanCurrent;
  };

  return (
    <div className={`${className}`} tabIndex={-1}>
      <WithDraggable prev={prev} next={nextVisibleIndex} onDragEndWithoutSlideChange={onDragEndWithoutSlideChange}>
        {draggableRef => (
          <Slider
            className={classnames('slider',
              {
                'has-indicator-xs': hasPositionIndicators,
                'has-indicator-md': hasPositionIndicatorsOnMdPlus,
              })}
            currentIndex={state.currentIndex}
            forwardRef={draggableRef}
          >
            {children.map((child, index) => {
              const isCurrentSlide = isOnCurrentSlide(index);
              return cloneElement(child, { isHidden: !isCurrentSlide });
            })}
          </Slider>
        )}
      </WithDraggable>
      <A11yAriaLive translation={ariaLiveTranslation} />
      {hasPositionIndicators &&
        <>
          <div className={classnames('carousel-icons', { 'd-md-none': !hasPositionIndicatorsOnMdPlus })}>
            <Partials.Indicator
              slideNumber={state.currentIndex * itemsPerSlide + itemsPerSlide}
              slidesCount={children.length}
              className='indicator'
            />
          </div>
          <CarouselArrows className={classnames(darkCarouselArrowsStyles, { 'd-md-none': !hasPositionIndicatorsOnMdPlus })}
            disablePrevArrow={isCurrent(minIndex)}
            disableNextArrow={isCurrentIndexBiggerThanLastVisibleIndex}
            onPrev={prev}
            onNext={next}
          />
        </>
      }
    </div>
  );
};

export const StyledCarousel = styled(Carousel)`
  position: relative;
  background-color: ${colors.DARK_BLUE_GREY_02};
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;

  &:focus {
    outline: none;
  }

  .slider {
    padding-bottom: 15px;
    &.has-indicator-xs {
      padding-bottom: 32px;
    }
  }

  .carousel-icons {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 50%;

    svg {
      display: block;
    }

    .indicator {
      padding-top: 0;
      padding-inline: 0;
      position: static;
      transform: translate(0);
      margin: 0;
    }
  }

  ${mediaMin.sm`
    .carousel-icons {
      min-width: 33.3333%;
    }
  `}

  ${mediaMin.md`
    .indicator-fragment:nth-child(2n) {
        display: none;
    }
    .slider:not(.has-indicator-md) {
      padding-bottom: 20px;
    }
    .slider.has-indicator-md {
      padding-bottom: 42px;
    }
    .carousel-icons {
      bottom: 20px;
    }
  `}
`;
