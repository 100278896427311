import gql from 'graphql-tag';
import { avCarouselFragment, StyledAVCarousel as AVCarousel } from './AVCarousel.jsx';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { makeMemoFragment } from '../../../utils/graphql';

export const avCarouselManualTopicVideoAdapterFragment = makeMemoFragment({
  name: 'AvCarouselManualTopicVideoAdapter',
  fragment() {
    return gql`fragment ${this.name} on ContentBundle {
          shortTitle
          ... on AssociationsAspect {
            videos {
              ...${avCarouselFragment.name}
            }
          }
        }
        ${avCarouselFragment.fragment()}
    `;
  },
});

export const AVCarouselManualTopicVideoAdapter = ({ contents, ...otherProps }) => {
  const hasFeatureFlag = useFeatureFlag();
  const [contentBundle] = contents;
  const hasVideos = !!contentBundle?.videos?.length;

  if (!hasFeatureFlag || !hasVideos) {
    return null;
  }

  const { shortTitle, videos } = contentBundle;

  return (
    <AVCarousel
      headlineTranslation={shortTitle}
      contents={videos}
      {...otherProps}
    />
  );
};
