import { suspendedComponentFactory } from '../SuspendedComponent/suspendedComponentFactory.jsx';

export const { StyledBetaLayer: BetaLayer } = suspendedComponentFactory(
  () => import('./BetaLayer'),
  { fallback: null },
);
export const { StyledLiveWelcomeLayer: LiveWelcomeLayer } = suspendedComponentFactory(
  () => import('./LiveWelcomeLayer.jsx'),
  { fallback: null },
);
