import gql from 'graphql-tag';

import { StyledPageHeadline as PageHeadline } from '../../PageHeadline.jsx';
import { CoCoComponentSelector, coCoComponentFragment, getCoCoCosWithContents } from '../CoCoComponentSelector.jsx';
import { StyledA11yLinkTargetMarker as A11yLinkTargetMarker } from '../../a11y/A11yLinkTargetMarker.jsx';
import { CoCoComponentBackgroundSelectorWithTheme as CoCoCoBackgroundSelectorWithTheme } from '../CoCoComponentBackgroundSelectorWithTheme.jsx';
import { StyledTopicHeaderWithTheme as TopicHeader, topicHeaderFragment } from '../../ContentBlocks/TopicHeader';
import { isValidList } from '../../../utils/contentUtils';
import { makeMemoFragment } from '../../../utils/graphql';
import { ids } from '../../../utils/staticElementIds';
import { noop } from '../../../utils/commons';
import { allItemsLinkFragment, StyledAllItemsLink as AllItemsLink } from './AllItemsLink.jsx';

export const autoTopicCoCoFragment = makeMemoFragment({
  name: 'AutoTopicCoCo',
  fragment() {
    return gql`fragment ${this.name} on AutoTopic {
      id
      pageHeadline
      isCurrent
      contentComposition {
        informationSpaces {
          id
          ...${coCoComponentFragment.name}
        }
      }
      ...${topicHeaderFragment.name}
      ...${allItemsLinkFragment.name}
    }
    ${coCoComponentFragment.fragment()}
    ${topicHeaderFragment.fragment()}
    ${allItemsLinkFragment.fragment()}
    `;
  },
});

export const AutoTopicCoCo = ({ autoTopic, className }) => {
  const {
    pageHeadline,
    contentComposition: { informationSpaces },
    isCurrent,
  } = autoTopic;
  const validInfoSpaces = informationSpaces.filter(infoSpace => isValidList(getCoCoCosWithContents(infoSpace)));

  return (
    <A11yLinkTargetMarker className={className} id={ids.quickNav.mainContent}>
      {isCurrent
        ? <PageHeadline>{pageHeadline}</PageHeadline>
        : <TopicHeader content={autoTopic} />
      }
      <CoCoCoBackgroundSelectorWithTheme>
        {validInfoSpaces.map((infoSpace, index) => (
          <CoCoComponentSelector key={infoSpace.id}
            infoSpace={infoSpace}
            isFirst={index === 0}
            bottomElementFn={(index === validInfoSpaces.length - 1)
              ? () => <AllItemsLink content={autoTopic} />
              : noop}
          />
        ))}
      </CoCoCoBackgroundSelectorWithTheme>
    </A11yLinkTargetMarker>
  );
};

export const StyledAutoTopicCoCo = AutoTopicCoCo;
