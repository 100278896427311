import gql from 'graphql-tag';
import { useRef, useState } from 'react';
import { styled } from '@linaria/react';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';

import { useTranslation } from './hooks/useTranslation.jsx';
import { INTERSECTION_OBSERVER_SETTINGS } from './LazyLoadDwPicture.jsx';
import { useEventListener } from './hooks/useEventListener';
import globals from '../utils/globals';
import { toLangCode } from '../utils/langMapper';
import { makeMemoFragment } from '../utils/graphql';
import { useMobileAppContext } from './hooks/mobileAppContext';

export const widgetIframeFragment = makeMemoFragment({
  name: 'WidgetIframe',
  fragment() {
    return gql`fragment ${this.name} on Widget {
        id
        language
        vendorCmpId
      }
    `;
  },
});

export const WidgetIframe = ({ widget, className, ...restProps }) => {
  const src = `/webapi/iframes/widget/${toLangCode(widget.language)}/${widget.id}`;
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);
  const { isMobileMode } = useMobileAppContext();
  const [height, setHeight] = useState(300);
  const iframeRef = useRef();
  useEventListener(globals.window, 'message', event => {
    if (event.source === iframeRef.current?.contentWindow && event.data.type === 'embed-size') {
      setHeight(event.data.height + 1);
    }
  });
  const lazySrc = inView ? src : '';
  return (
    <div ref={inViewRef} className={`${className} dw-widget`}>
      <iframe
        className={classnames({ cmplazyload: inView })}
        ref={iframeRef}
        title={useTranslation('content_block.widget.headline')}
        src={isMobileMode ? lazySrc : 'about:blank'}
        data-cmp-vendor={widget.vendorCmpId}
        data-cmp-src={lazySrc}
        data-cmp-preview="100%x300"
        style={{
          height,
        }}
        {...restProps}
      >
      </iframe>
    </div>
  );
};

export const StyledWidgetIframe = styled(WidgetIframe)`
  iframe {
    border: none;
    width: 100%;
    min-height: 300px;
  }
`;
